import {
  LinearProgress,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React, { MouseEventHandler, useState, useEffect } from 'react';
import { useAudioCurrentTime } from '../../hooks/useAudioCurrentTime';
import { useAudio } from '../../providers/AudioProvider';
import { CustomTheme } from '../../types';
import { formatTime } from '../../utils/formatTime';
import { getClickedPercentageOfElement } from '../../utils/getClickedPercentageOfElement';
import Wavesurfer from 'wavesurfer.js';

const AUDIO_PLAYER_WAVEFORM_ID = 'waveform-audio-player';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    waveFormContainer: {
      width: '100%',
    },
    time: {
      margin: theme.spacing(0, 2),
      minWidth: 35,
      '&:first-child': { marginLeft: 0 },
      '&:last-child': { marginRight: 0 },
    },
  })
);
export interface AudioWaveformProgressProps {
  showTime?: boolean;
}

export const AudioWaveformProgress = ({
  showTime,
}: AudioWaveformProgressProps) => {
  const classes = useStyles();
  const audio = useAudio();
  const { onSeek, duration } = useAudio();
  const currentTime = useAudioCurrentTime();
  const [wavesurfer, setWaveSurfer] = useState<Wavesurfer | null>(null);
  const theme: CustomTheme = useTheme();

  useEffect(() => {
    if (wavesurfer) {
      wavesurfer.setVolume(0);
      wavesurfer.on('load', () => {
        wavesurfer.pause();
      });
    }
  }, [wavesurfer]);

  useEffect(() => {
    setWaveSurfer(
      Wavesurfer.create({
        container: `#${AUDIO_PLAYER_WAVEFORM_ID}`,
        interact: false,
        waveColor: theme.palette.background.contrast,
        cursorWidth: 0,
        height: 32,
        hideScrollbar: true,
        barMinHeight: 1,
        barGap: 2,
        barWidth: 1,
        barRadius: 1,
        responsive: true,
        progressColor: theme.palette.common.white,
      })
    );
  }, []);

  useEffect(() => {
    if (audio.currentTrack && wavesurfer) {
      wavesurfer.cancelAjax();
      const mediaLink = audio.currentTrack.Media.find((m) => m.isFullMedia)
        ?.link;
      if (mediaLink) {
        wavesurfer.load(mediaLink);
      }
    }
  }, [audio.currentTrack, wavesurfer]);

  useEffect(() => {
    if (wavesurfer) {
      const seekTo = currentTime / (audio.duration || 1);
      wavesurfer.seekTo(seekTo > 1 ? 1 : seekTo < 0 ? 0 : seekTo);
    }
  }, [currentTime, wavesurfer]);

  const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    const pct = getClickedPercentageOfElement(e);
    onSeek(pct);
  };

  return (
    <div className={classes.container}>
      {showTime && (
        <Typography className={classes.time}>
          {formatTime(currentTime)}
        </Typography>
      )}
      <div
        className={classes.waveFormContainer}
        onClick={handleClick}
        id={AUDIO_PLAYER_WAVEFORM_ID}
      ></div>
      {showTime && (
        <Typography className={classes.time}>
          {formatTime(audio.duration)}
        </Typography>
      )}
    </div>
  );
};
