import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableContainer,
  Paper,
  Typography,
  CircularProgress,
  TablePagination,
} from '@material-ui/core';

import { useGetUsersApiCall } from '../../../../api/users/hooks';
import {
  GetSearchUsersVariables,
  GetUsersVariables,
} from '../../../../api/users';
import UserInfoDisplay from './UserInfoDisplay';
import UsersSearch from './UsersSearch';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      textAlign: 'left',
      padding: theme.spacing(1),
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
    mainContent: {
      marginTop: theme.spacing(5),
      background: theme.palette.background.default,
    },
    button: {
      display: 'flex',
      margin: theme.spacing(1),
      marginLeft: theme.spacing(5),
    },
    table: {},
    tableHeader: { fontWeight: 'bold' },
    tableRow: { background: theme.palette.background.default },
    searchBar: {
      display: 'flex',
    },
  })
);

export default function Users() {
  const classes = useStyles();
  const [page, setPage] = useState<number>(0);
  const [
    searchVariables,
    setSearchVariables,
  ] = useState<GetSearchUsersVariables>({
    name: '',
    email: '',
    subscription_type: '',
  });
  const rowsPerPage = 50;
  const usersResult = useGetUsersApiCall({
    ...searchVariables,
    offset: page * rowsPerPage,
  });
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const pageCount = usersResult.data?.pageInfo.total
    ? usersResult.data?.pageInfo.total
    : 1;
  return (
    <div>
      <Typography variant="h4" className={classes.title}>
        Users
      </Typography>

      <UsersSearch onSearch={setSearchVariables} resetPageCount={setPage} />

      <div className={classes.mainContent}>
        <TablePagination
          rowsPerPageOptions={[rowsPerPage]}
          component="div"
          count={pageCount}
          page={page}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
        />

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            {usersResult.loading ? (
              <CircularProgress />
            ) : (
              <>
                <TableBody>
                  {usersResult.data?.users.map(
                    (userInfo: GetUsersVariables) => (
                      <UserInfoDisplay
                        userInfo={userInfo}
                        onDeleteUser={usersResult.refetch}
                        onEditUser={usersResult.refetch}
                      />
                    )
                  )}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
