import { makeStyles, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import { Plan } from '../../api/plans';
import { CustomTheme } from '../../types';
import { getPlanAmountString } from './utils/getPlanAmountString';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    planBox: {
      cursor: 'pointer',
      padding: theme.spacing(3),
      marginRight: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      maxWidth: 250,
      background: '#EFEFEF',
      borderRadius: 8,
      color: theme.palette.common.black,
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        marginRight: theme.spacing(0),
        marginTop: theme.spacing(1.5),
      },
    },
    inactive: {
      '&&': {
        background: '#484143',
        color: '#A2A2A2',
      },
    },
    smallText: { fontSize: 10, marginTop: theme.spacing(-0.5) },
    description: { fontWeight: 'bold', fontSize: 14 },
    selectOption: {
      margin: theme.spacing(3, 0),
    },
    plans: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
  })
);
export interface PlanSelectorProps {
  yearlyPlan?: Plan;
  monthlyPlan?: Plan;
  onSelect: (plan: Plan) => void;
  selectedPlanId?: string;
}

const PlanBox = ({
  plan,
  description,
  active,
  onClick,
}: {
  description: string;
  plan: Plan;
  active: boolean;
  onClick: VoidFunction;
}) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.planBox, active === false && classes.inactive)}
      onClick={onClick}
    >
      <Typography variant="h6" color="inherit">
        {getPlanAmountString(plan)}
      </Typography>
      <Typography className={classes.description} color="inherit">
        {description}
      </Typography>
    </div>
  );
};

export const PlanSelector = ({
  onSelect,
  yearlyPlan,
  monthlyPlan,
  selectedPlanId,
}: PlanSelectorProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.selectOption} variant="body2">
        Select an option:
      </Typography>
      <div className={classes.plans}>
        {yearlyPlan && (
          <PlanBox
            onClick={() => onSelect(yearlyPlan)}
            plan={yearlyPlan}
            active={selectedPlanId === yearlyPlan.id}
            description="Save on your 1-year subscription by making a one time payment"
          />
        )}
        {monthlyPlan && (
          <PlanBox
            onClick={() => onSelect(monthlyPlan)}
            plan={monthlyPlan}
            active={selectedPlanId === monthlyPlan.id}
            description="Year Contract billed monthly. After 1 year, contract is month-to-month"
          />
        )}
      </div>
    </div>
  );
};
