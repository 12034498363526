import { CircularProgress, makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import { CustomTheme } from '../../types';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: { textAlign: 'center', marginTop: theme.spacing(1) },
  })
);
export interface PageLoadingSpinnerProps {}

export const PageLoadingSpinner = ({}: PageLoadingSpinnerProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CircularProgress />
    </div>
  );
};
