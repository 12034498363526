import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import theme from './theme';
import { ThemeProvider } from '@material-ui/core/styles';
import { useSessionState, SessionContext } from './providers/SessionProvider';
import { useAudioState, AudioContext } from './providers/AudioProvider';
import { STRIPE_PUBLIC_KEY } from './constants/values';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { SearchContext, useSearchState } from './providers/SearchProvider';
import {
  PurchasedTracksContext,
  usePurchasedTracksState,
} from './providers/PurchasedTracksProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  FavoriteTracksContext,
  useFavoriteTracksState,
} from './providers/FavoriteTracksProvider';

type Props = {
  children: any;
};

const Providers = ({ children }: Props) => {
  const sessionState = useSessionState();
  const favoriteTracksState = useFavoriteTracksState({
    user: sessionState.user,
  });
  const purchasedTracksState = usePurchasedTracksState({
    user: sessionState.user,
  });
  const audioState = useAudioState();
  const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
  const searchState = useSearchState();

  return (
    <BrowserRouter>
      <Elements stripe={stripePromise}>
        <SessionContext.Provider value={sessionState as any}>
          <FavoriteTracksContext.Provider value={favoriteTracksState}>
            <PurchasedTracksContext.Provider value={purchasedTracksState}>
              <AudioContext.Provider value={audioState}>
                <SearchContext.Provider value={searchState}>
                  <ThemeProvider theme={theme}>
                    <>
                      <CssBaseline />
                      {children}
                    </>
                  </ThemeProvider>
                </SearchContext.Provider>
              </AudioContext.Provider>
            </PurchasedTracksContext.Provider>
          </FavoriteTracksContext.Provider>
        </SessionContext.Provider>
      </Elements>
    </BrowserRouter>
  );
};

export default Providers;
