import { Coupon, CouponDuration } from '../api/coupons';

const getDurationText = (coupon: Coupon) =>
  coupon.duration === CouponDuration.REPEATING
    ? `for ${coupon.duration_in_months} months`
    : `${coupon.duration}`;

export const getCouponEffectString = (coupon: Coupon): string => {
  if (coupon.amount_off) {
    return `$${coupon.amount_off / 100} off ${getDurationText(coupon)}`;
  } else if (coupon.percent_off) {
    return `${coupon.percent_off}% off ${getDurationText(coupon)}`;
  }
  return ``;
};
