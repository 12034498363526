import { Button, makeStyles, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';
import { CustomTheme } from '../../types';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {
      textAlign: 'center',
      '& > *': { marginTop: theme.spacing(2) },
    },
  })
);
export interface CheckoutTrackSuccessProps {}

export const CheckoutTrackSuccess = ({}: CheckoutTrackSuccessProps) => {
  const classes = useStyles();
  const match = useRouteMatch();

  return (
    <div className={classes.container}>
      <Typography variant="h4">
        You have been successfully purchased{' '}
        {decodeURI((match.params as any).name)}
      </Typography>
      <Link to={AppRoutes.MY_LIBRARY}>
        <Button color="primary" variant="contained">
          Take me to my library
        </Button>
      </Link>
    </div>
  );
};
