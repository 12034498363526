import { makeStyles, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { useGetPaymentMethod } from '../../api/checkout/hooks';
import { CustomTheme } from '../../types';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {},
  })
);
export interface PaymentSettingsProps {}

export const PaymentSettings = ({}: PaymentSettingsProps) => {
  const classes = useStyles();
  const method = useGetPaymentMethod();

  return (
    <div className={classes.container}>
      <Typography>
        Current Card:{' '}
        {method?.data ? `*****${method.data.last4}` : 'Loading...'}
      </Typography>
    </div>
  );
};
