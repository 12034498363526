import { Plan, PlanLevel } from '../../../api/plans';

export const findValidPlans = (
  plans: Plan[]
): { yearlyPlan?: Plan; monthlyPlan?: Plan } => ({
  yearlyPlan: plans.find(
    (p) =>
      p.interval === 'year' &&
      p.metadata.level === PlanLevel.SMALL_BUSINESS &&
      p.metadata.autoRenew
  ),
  monthlyPlan: plans.find(
    (p) =>
      p.interval === 'month' &&
      p.metadata.level === PlanLevel.SMALL_BUSINESS &&
      p.metadata.autoRenew
  ),
});
