import { InputLabel, makeStyles, TextField } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { Coupon } from '../../api/coupons';
import { CustomTheme } from '../../types';
import {
  CardElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { CouponInput } from '../CouponInput/CouponInput';
import { getPrettyCurrency } from '../../utils/getPrettyCurrencty';
import { PaymentRequest } from '@stripe/stripe-js';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    paymentRequest: {},
    container: {},
    cardDetailsContainer: {
      marginTop: theme.spacing(2),
    },
    cardElement: {
      borderBottom: `1px solid #919191`,
      padding: theme.spacing(2, 0),
    },
  })
);
export interface CheckoutFieldsProps {
  onCoupon?: (coupon: Coupon | null) => void;
  hideCardInput?: boolean;
  hideCouponInput?: boolean;
  amountWithDiscount: number;
}

export const CheckoutFields = ({
  hideCardInput,
  hideCouponInput,
  onCoupon,
  amountWithDiscount,
}: CheckoutFieldsProps) => {
  const classes = useStyles();
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
    null
  );

  // Leaving these commented out until we are ready to implement more fully
  // useEffect(() => {
  //   if (stripe) {
  //     const pr = stripe.paymentRequest({
  //       country: 'US',
  //       currency: 'usd',
  //       total: {
  //         label: 'PPMC Purchase',
  //         amount: amountWithDiscount,
  //       },
  //       requestPayerName: true,
  //       requestPayerEmail: true,
  //     });
  //     console.log(pr);

  //     // Check the availability of the Payment Request API.
  //     pr.canMakePayment().then((result) => {
  //       console.log(result);
  //       if (result) {
  //         setPaymentRequest(pr);
  //       }
  //     });
  //   }
  // }, [stripe]);

  return (
    <>
      <TextField
        name="total"
        label="Total"
        value={`$${getPrettyCurrency(amountWithDiscount / 100)}`}
        fullWidth
      />
      {!hideCardInput && (
        <>
          <div className={classes.cardDetailsContainer}>
            <InputLabel shrink>Card Details</InputLabel>
            <CardElement className={classes.cardElement} />
          </div>
          {/* <div className={classes.paymentRequest}>
            {paymentRequest ? (
              <PaymentRequestButtonElement options={{ paymentRequest }} />
            ) : null}
          </div> */}
        </>
      )}
      {onCoupon && !hideCouponInput && <CouponInput onCoupon={onCoupon} />}
    </>
  );
};
