import { LinearProgress, makeStyles, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React, { MouseEventHandler, useState, useEffect } from 'react';
import { useAudioCurrentTime } from '../../hooks/useAudioCurrentTime';
import { useAudio } from '../../providers/AudioProvider';
import { CustomTheme } from '../../types';
import { formatTime } from '../../utils/formatTime';
import { getClickedPercentageOfElement } from '../../utils/getClickedPercentageOfElement';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {},
    progressRoot: {
      height: 12,
      background: theme.palette.background.contrast,
    },
    bar: {
      backgroundColor: theme.palette.common.white,
    },
  })
);
export interface AudioProgressProps {}

export const AudioProgress = ({}: AudioProgressProps) => {
  const classes = useStyles();
  const audio = useAudio();
  const currentTime = useAudioCurrentTime();

  const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    const pct = getClickedPercentageOfElement(e);
    audio.onSeek(pct);
  };

  return (
    <div className={classes.container} onClick={handleClick}>
      <LinearProgress
        value={(currentTime / (audio.duration || 1)) * 100}
        variant="determinate"
        classes={{ root: classes.progressRoot, barColorPrimary: classes.bar }}
      />
    </div>
  );
};
