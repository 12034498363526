import {
  Button,
  makeStyles,
  MobileStepper,
  Link,
  Typography,
} from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { createStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { useIsMobile } from '../../hooks/isMobile';
import ContentPageLayout from '../../layouts/ContentPageLayout';
import HeaderImageLayout from '../../layouts/HeaderImageLayout';
import { CustomTheme } from '../../types';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    divider: { borderTop: `1px solid #565656`, margin: theme.spacing(3, 0) },
    stepperDots: {
      flexWrap: 'wrap',
    },
    pointsSection: {
      '&>*': { marginBottom: theme.spacing(4) },
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    container: {
      '& img': {
        width: '100%',
        marginTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
    containerRow: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: theme.spacing(4, 0),
      [theme.breakpoints.up('md')]: {
        '& > *': {
          margin: theme.spacing(0, 3),
          flex: 1,
        },
        '& > *:first-child': {
          marginLeft: 0,
        },
        '& > *:last-child': {
          marginRight: 0,
        },
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        '& > *': {
          margin: theme.spacing(2, 0),
        },
      },
    },
    description: {
      fontSize: 28,
      [theme.breakpoints.down('sm')]: { fontSize: 20 },
    },
    sectionContent: {},
    sectionContainer: {},

    title: { margin: theme.spacing(2, 0) },
    firstContent: {
      borderRight: `8px solid ${theme.palette.common.white}`,
      marginRight: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        width: 'initial',
        marginRight: 'initial',
        borderRight: 'none',
      },
      '&>*': {
        maxWidth: 600,
        [theme.breakpoints.down('sm')]: {
          maxWidth: 'unset',
        },
      },
      width: '100%',
    },
  })
);

const VIDEOS = [
  'https://www.youtube.com/embed/fTkbqmxPP0E',
  'https://www.youtube.com/embed/MMa-_5LPfro',
  'https://www.youtube.com/embed/O5l6_-MtR6s',
  'https://www.youtube.com/embed/H01OWnaaqWo',
  'https://www.youtube.com/embed/KqrkEJjcK1A',
  'https://www.youtube.com/embed/HgBrxKAAPN4',
  'https://www.youtube.com/embed/RTM88_KDoY4',
  'https://player.vimeo.com/video/167788267?wmode=opaque&api=1&autoplay=0',
  'https://www.youtube.com/embed/khVPNmBPNDA',
  'https://www.youtube.com/embed/Fn8FUmxpDqU',
  'https://www.youtube.com/embed/fkQBVoS9lAo',
  'https://www.youtube.com/embed/c6z7tjuHVa8',
  'https://player.vimeo.com/video/145897976?wmode=opaque&api=1&autoplay=0',
  'https://www.youtube.com/embed/VHPTV1OLkss',
  'https://www.youtube.com/embed/jQZui1a7O-Y',
  'https://www.youtube.com/embed/OjvWL1QdpgI',
  'https://www.youtube.com/embed/7lWk_rWoyCI',
  'https://www.youtube.com/embed/vuI7nuweTW4',
  'https://www.youtube.com/embed/0IBUddknawg',
  'https://www.youtube.com/embed/UHvGhjf2yaM',
  'https://player.vimeo.com/video/107890631?wmode=opaque&api=1&autoplay=0',
  'https://player.vimeo.com/video/104429716?wmode=opaque&api=1&autoplay=0',
  'https://www.youtube.com/embed/azbi6t7BMho',
  'https://player.vimeo.com/video/101350322?wmode=opaque&api=1&autoplay=0',
  'https://player.vimeo.com/video/105606091?wmode=opaque&api=1&autoplay=0',
  'https://www.youtube.com/embed/HToOG1X1knA',
  'https://www.youtube.com/embed/X1nUmphWBQg',
];
export interface CustomMusicPageProps {}

export const CustomMusicSection = ({
  children,
  title,
}: {
  children: string | any;
  title: string | JSX.Element;
}) => {
  const classes = useStyles();
  return (
    <div className={classes.sectionContainer}>
      <div className={classes.title}>
        <Typography variant="body2">{title}</Typography>
      </div>
      <div className={classes.sectionContent}>
        <Typography variant="body2">{children}</Typography>
      </div>
    </div>
  );
};

export const CustomMusicPage = ({}: CustomMusicPageProps) => {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const isMobile = useIsMobile();

  return (
    <HeaderImageLayout
      title="Custom Music"
      imgSrc="https://s3-us-west-2.amazonaws.com/pleasantpictures-images/BIO_PHOTO.jpg"
      action={
        <Button
          variant="contained"
          color="secondary"
          href="mailto:info@pleasantpictures.club"
          target="_blank"
          size={isMobile ? 'small' : 'large'}
        >
          Contact Us
        </Button>
      }
    >
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.firstContent}>
            <Typography variant="body2" className={classes.description}>
              Score your film or project with us! <br />
              <br />
              We LOVE doing custom work. From podcast intros to commercials/ads
              to full film scores, we do it all. <br />
              <br />
              We’d love to hear about your project and make it sound the best it
              can.
            </Typography>
          </div>
          <div className={classes.pointsSection}>
            <CustomMusicSection title="How do we deliver?">
              We offer unlimited revisions. We guarantee you will love our work.
            </CustomMusicSection>
            <CustomMusicSection title={'Chat about your project'}>
              <>
                Hit us up: info@pleasantpictures.club <br />
                (801) 380-5993
              </>
            </CustomMusicSection>
          </div>
        </div>
        <div className={classes.divider}></div>
        <Typography variant="h5">Our Work</Typography>
        <MobileStepper
          steps={VIDEOS.length}
          activeStep={activeIndex}
          position={'static'}
          classes={{ dots: classes.stepperDots }}
          nextButton={
            <Button
              size="small"
              onClick={() => setActiveIndex(activeIndex + 1)}
              disabled={activeIndex === VIDEOS.length - 1}
            >
              Next
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={() => setActiveIndex(activeIndex - 1)}
              disabled={activeIndex === 0}
            >
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        />
        <iframe
          width="100%"
          height="450"
          src={VIDEOS[activeIndex]}
          frameBorder={0}
          allow="encrypted-media"
          allowFullScreen
        ></iframe>
      </div>
    </HeaderImageLayout>
  );
};
