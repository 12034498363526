import {
  FormControl,
  FormControlLabel,
  Link,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React, { useMemo, useState } from 'react';
import { useGetFavoriteTracksApiCall } from '../../api/favoriteTracks/hooks';
import { Track } from '../../api/tracks';
import { useGetPurchasedTracksApiCall } from '../../api/tracks/hooks';
import { AppRoutes } from '../../constants/routes';
import WithEdgePaddingLayout from '../../layouts/WithEdgePaddingLayout';
import { useSession } from '../../providers/SessionProvider';
import { CustomTheme } from '../../types';
import { TrackList } from '../TrackList/TrackList';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: { textAlign: 'center' },
    link: { wordBreak: 'break-word' },
    tracks: {},
  })
);
export interface MyLibraryProps {}

export const MyLibrary = ({}: MyLibraryProps) => {
  const classes = useStyles();
  const [showFavorites, setShowFavorites] = useState<boolean>(false);
  const favoriteTracks = useGetFavoriteTracksApiCall();
  const purchasedTracks = useGetPurchasedTracksApiCall();
  const session = useSession();
  const shownTracks: Track[] = useMemo(() => {
    if (showFavorites) {
      return favoriteTracks.data?.map((track) => track.Track) || [];
    } else {
      return purchasedTracks.data?.map((track) => track.Track) || [];
    }
  }, [favoriteTracks.data, purchasedTracks.data, showFavorites]);

  return (
    <WithEdgePaddingLayout>
      <div className={classes.container}>
        <Typography variant="h3">My Library</Typography>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="showFavorites"
            name="showFavorites"
            value={showFavorites}
            onChange={(_e, val) =>
              setShowFavorites(val === 'true' ? true : false)
            }
          >
            <FormControlLabel
              value={true}
              control={<Radio />}
              label="Favorites"
            />
            <FormControlLabel value={false} control={<Radio />} label="Owned" />
          </RadioGroup>
        </FormControl>
        {showFavorites && (
          <div>
            <Typography>
              Link to this list:{' '}
              <Link
                href={`https://pleasantpictures.club${AppRoutes.FAVORITES}/${session.user?._id}`}
                target="_blank"
                className={classes.link}
              >
                https://pleasantpictures.club{AppRoutes.FAVORITES}/
                {session.user?._id}
              </Link>
            </Typography>
          </div>
        )}
        <div className={classes.tracks}>
          <TrackList tracks={shownTracks} />
        </div>
      </div>
    </WithEdgePaddingLayout>
  );
};
