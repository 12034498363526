import { fetcher, FetcherRequestConfig, HTTPMethod } from '../../utils/fetcher';
import _ from 'lodash';
import { User } from '@sentry/types';
import { PaymentMethod } from '@stripe/stripe-js';

export type Plan = {
  active: boolean;
  amount: number;
  billing_scheme: string;
  id: string;
  interval: 'year' | 'month';
  interval_count: number;
  metadata: {
    autoRenew: 'true' | 'false';
  };
  nickname: string;
  product: string;
};

export type CheckoutPlanBody = {
  coupon?: string;
  planID: string;
  token: string;
};

export type CheckoutPlanResponse = User;

export const checkoutPlan = async (
  body: CheckoutPlanBody,
  options: FetcherRequestConfig
): Promise<CheckoutPlanResponse> => {
  const result = await fetcher<CheckoutPlanResponse>('/stripe/checkout', {
    ...options,
    method: HTTPMethod.POST,
    body,
  });

  const response = await result.json();
  return response;
};

export type CheckoutTrackPackBody = {
  code?: string;
  packID: string;
  token?: string;
};

export type CheckoutTrackPackResponse = null;
export const checkoutTrackPack = async (
  body: CheckoutTrackPackBody,
  options: FetcherRequestConfig
): Promise<CheckoutTrackPackResponse> => {
  const result = await fetcher<CheckoutTrackPackResponse>(
    `/stripe/purchaseTrack/pack${body.code ? '/code' : ''}`,
    {
      ...options,
      method: HTTPMethod.POST,
      body,
    }
  );
  return null;
};

export type CheckoutTrackBody = {
  code?: string;
  token: string;
  trackID: number;
};

export type CheckoutTrackResponse = {};
export const checkoutTrack = async (
  body: CheckoutTrackBody,
  options: FetcherRequestConfig
): Promise<CheckoutTrackResponse> => {
  const result = await fetcher<CheckoutTrackResponse>(`/stripe/purchaseTrack`, {
    ...options,
    method: HTTPMethod.POST,
    body,
  });
  return {};
};

export type GetPaymentMethodResponse = PaymentMethod.Card;

export const getPaymentMethod = async (
  body: {},
  options: FetcherRequestConfig
): Promise<GetPaymentMethodResponse> => {
  const result = await fetcher<GetPaymentMethodResponse>(
    '/stripe/paymentMethod',
    {
      ...options,
      method: HTTPMethod.GET,
    }
  );
  const response = await result.json();
  return response;
};

export type CheckoutPlanV2Body = {
  couponID?: string;
  planID: string;
  paymentMethodID: string;
};

export type CheckoutPlanV2Response = User;

export const checkoutPlanV2 = async (
  body: CheckoutPlanV2Body,
  options: FetcherRequestConfig
): Promise<CheckoutPlanV2Response> => {
  const result = await fetcher<CheckoutPlanV2Response>(
    '/stripe/paymentsV2/subscribe',
    {
      ...options,
      method: HTTPMethod.POST,
      body,
    }
  );

  const response = await result.json();
  return response;
};

export type CheckoutTrackV2Body = {
  code?: string;
  paymentMethodId: string;
  trackID: number;
};

export type CheckoutTrackV2Response = {};
export const checkoutTrackV2 = async (
  body: CheckoutTrackV2Body,
  options: FetcherRequestConfig
): Promise<CheckoutTrackV2Response> => {
  const result = await fetcher<CheckoutTrackV2Response>(
    `/stripe/paymentsV2/purchaseTrack`,
    {
      ...options,
      method: HTTPMethod.POST,
      body,
    }
  );
  return {};
};
