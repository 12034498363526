import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import { CustomTheme } from '../../types';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {},
  })
);
export interface PrivacyPolicyProps {}

export const PrivacyPolicy = ({}: PrivacyPolicyProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>
        <div>
          <p>
            <b>
              <span>PRIVACY POLICY</span>
            </b>
          </p>

          <p>
            <span>
              PLEASE READ THIS PRIVACY POLICY CAREFULLY BEFORE USING THIS SITE.
            </span>
          </p>

          <p>
            <span>
              By using pleasantpictures.club, you are consenting to this Privacy
              Policy.
            </span>
          </p>

          <p>
            <span>
              Pleasant Pictures Music Library LLC, a limited liability company
              registered in Utah, doing business as, DBA Pleasant Pictures Music
              Club is operating this website and may revise and update this
              Privacy Policy at any time. Your continued usage of the
              <b>pleasantpictures.club </b>website (“Site”) will mean you accept
              those changes.
            </span>
          </p>

          <p>
            <b>
              <span>Pleasant Pictures Music Library LLC</span>
            </b>
            <span>
              {' '}
              is committed to protecting your privacy online. This Privacy
              Policy describes the personal information we collect through this
              website at
              <span>  </span>
              <b>pleasantpictures.club</b> (the “Site”), and how we collect that
              information.
            </span>
          </p>

          <p>
            <b>
              <span>What information do we collect?</span>
            </b>
          </p>

          <p>
            <span>
              We collect information from you when you place an order, subscribe
              to our music library, subscribe to our newsletter, respond to a
              survey, or fill out a form.
            </span>
          </p>

          <p>
            <span>
              When ordering or registering on our site, as appropriate, you may
              be asked to enter your: name, email address, business name,
              mailing address, phone number or credit card information, industry
              or information about what you are using your music for.
            </span>
          </p>

          <p>
            <b>
              <span>Registration Data and Privacy</span>
            </b>
          </p>

          <p>
            <span>
              In order to access some of the services on this site, you may be
              required to use an account and password that can be obtained by
              completing our online registration form, which requests certain
              information and data (“Registration Data”), and maintaining and
              updating your Registration Data as required. By registering, you
              agree that all information provided in the Registration Data is
              true and accurate and that you will maintain and update this
              information as required in order to keep it current, complete, and
              accurate.
            </span>
          </p>

          <p>
            <b>
              <span>What do we use your information for?</span>
            </b>
          </p>

          <p>
            <span>
              Any of the information we collect from you may be used in any of
              the following ways:
            </span>
          </p>

          <p>
            <span></span>
            <span>
              To personalize your experience; (your information helps us to
              better respond to your individual needs)
            </span>
          </p>

          <p>
            <span></span>
            <span>
              To improve our website; (we continually strive to improve our
              website offerings based on the information and feedback we receive
              from you)
            </span>
          </p>

          <p>
            <span></span>
            <span>
              To improve customer service; (your information helps us to more
              effectively respond to your customer service requests and support
              needs)
            </span>
          </p>

          <p>
            <span></span>
            <span>To process transactions;</span>
          </p>

          <p>
            <span></span>
            <span>
              To administer a contest, promotion, survey or other site feature;
            </span>
          </p>

          <p>
            <span></span>
            <span>To send periodic emails;</span>
          </p>

          <p>
            <span>
              The email address you provide for order processing, may be used to
              send you information and updates pertaining to your order, in
              addition to receiving occasional company news, updates, related
              product or service information, etc.
            </span>
          </p>

          <p>
            <i>
              <span>
                Note: If at any time you would like to unsubscribe from
                receiving future emails, we include detailed unsubscribe
                instructions at the bottom of each email.
              </span>
            </i>
          </p>

          <p>
            <span>
              Your information, whether public or private, will not be sold,
              exchanged, transferred, or given to any other company for any
              reason whatsoever, without your consent, other than for the
              express purpose of delivering the purchased product or service
              requested.
            </span>
          </p>

          <p>
            <b>
              <span>Protecting your information</span>
            </b>
          </p>

          <p>
            <span>
              We implement a variety of security measures to maintain the safety
              of your personal information when you place an order or enter,
              submit, or access your personal information.
            </span>
          </p>

          <p>
            <span>
              Our payment gateway and processor offers the use of a secure
              server. All supplied sensitive/credit information is transmitted
              via Secure Socket Layer (SSL) technology and then encrypted into
              the payment gateway provider’s database only to be accessible by
              those authorized with special access rights to such systems, and
              are required to keep the information confidential.
            </span>
          </p>

          <p>
            <span>
              After a transaction, your private information (credit cards,
              social security numbers, financials, etc.) will not be stored on
              our servers.
            </span>
          </p>

          <p>
            <b>
              <span>Exceptions to Privacy Policy</span>
            </b>
          </p>

          <p>
            <span>
              While we are committed to keeping your information secure and
              private, we have the following exceptions to our Privacy Policy:
              We will release specific information about you or your account to
              comply with any valid legal inquiry or process such as a search
              warrant, subpoena, statute or court order. We will also release
              specific information in special cases, such as if there is an
              attempted breach of the security of the sites, or a physical or
              property threat to you or others. We may also transfer user
              information, including personally identifiable information, in
              connection with a business merger, consolidation, the sale of
              related assets or business division or other fundamental business
              change. Further, the information you enter when making a purchase
              will be shared with payment processors, financial gateways, and
              your credit card company to authorize credit card payments. Such
              information may also be shared with necessary third parties solely
              for the purpose of carrying out the transactions. Non-personally
              identifiable visitor information may be provided to other parties
              for marketing, advertising, or other uses.
            </span>
          </p>

          <p>
            <span>
              Please note that if you give out personal information online
              through a discussion board or posting site, that information can
              be collected and used by third parties. Although we strive to
              protect our users’ privacy, we cannot guarantee the security of
              information you post in these forums. You disclose such
              information at your own risk.
            </span>
          </p>

          <p>
            <b>
              <span>Third party links</span>
            </b>
          </p>

          <p>
            <span>
              Occasionally, at our discretion, we may include or offer third
              party products or services on our website or we may link to a
              third party’s website for informational purposes. These third
              party sites have separate and independent privacy policies. We
              therefore have no responsibility or liability for the content and
              activities of these linked sites. You may login to our website
              through a third party account, and we may receive some personally
              identifying information through that linked account. Nonetheless,
              we seek to protect the integrity of our site and welcome any
              feedback about these sites.
            </span>
          </p>

          <p>
            <b>
              <span>Cookies</span>
            </b>
          </p>

          <p>
            <span>
              Cookies are small files that a site or its service provider
              transfers to your computer’s hard drive through your web browser
              (if you allow) that enables the site’s or service provider’s
              systems to recognize your browser and capture and remember certain
              information
            </span>
          </p>

          <p>
            <span>
              We may use cookies to help us understand and save your preferences
              for future visits, keep track of advertisements and compile
              aggregate data about site traffic and site interaction so that we
              can offer better site experiences and tools in the future. We may
              contract with third-party service providers to assist us in better
              understanding our site visitors. These service providers are not
              permitted to use the information collected on our behalf except to
              help us conduct and improve our business.
            </span>
          </p>

          <p>
            <span>
              You have the choice to accept or decline cookies. Most web
              browsers automatically accept the use of cookies but you may
              modify your browser settings to decline the use of cookies.
              However, declining the use of cookies may prevent users from
              logging in correctly, when applicable, and may decrease the
              functionality of the site on the user’s computer.
            </span>
          </p>

          <p>
            <b>
              <span>California Online Privacy Protection Act Compliance</span>
            </b>
          </p>

          <p>
            <span>
              Because we value your privacy we have taken the necessary
              precautions to be in compliance with the California Online Privacy
              Protection Act. We therefore will not distribute your personal
              information to outside parties without your consent except as
              noted above. At this time we do not respond to DNT (Do Not Track)
              signals.
            </span>
          </p>

          <p>
            <b>
              <span>Children’s Online Privacy Protection Act Compliance</span>
            </b>
          </p>

          <p>
            <span>
              We are in compliance with the requirements of COPPA (Children’s
              Online Privacy Protection Act). We do not knowingly collect any
              information from anyone under 13 years of age. Our website,
              products and services are all directed to people who are at least
              13 years old or older. We recommend that anyone under 18 years of
              age participate and use our website, products or services only
              with parental permission and supervision.
            </span>
          </p>

          <p>
            <span>
              We do not intend to collect any personal information from children
              under the age of 18. If a parent or guardian of a child who has
              provided us with such personal information would like the
              information deleted from our records, he or she should contact us
              at the email address listed at the bottom of this policy
              statement. We will then delete the child’s personal information
              from our files.
            </span>
          </p>

          <p>
            <span>
              If we become aware that personally identifiable information
              regarding a child under the age of 13 has been collected at the
              Site, we will use such information for the sole purpose of
              contacting a parent or guardian of the child to obtain verifiable
              parental consent. If we cannot obtain consent after a reasonable
              time, or if when contacted a parent or guardian requests that we
              do not use or maintain such information, we will make reasonable
              efforts to delete it from our records. Upon request by a parent of
              guardian,
              <b>Pleasant Pictures Music Library LLC</b> will provide a
              description of the specific types of personal information
              collected from a child who is under the age of 13.
            </span>
          </p>

          <p>
            <b>
              <span>Online Privacy Policy Only</span>
            </b>
          </p>

          <p>
            <span>
              This online Privacy Policy applies only to information collected
              through our website and not to information collected offline.
            </span>
          </p>

          <p>
            <b>
              <span>Terms and Conditions</span>
            </b>
          </p>

          <p>
            <span>
              Please also visit our Terms and Conditions section establishing
              the use, disclaimers, and limitations of liability governing the
              use of our website.
            </span>
          </p>

          <p>
            <b>
              <span>Contacting Us</span>
            </b>
          </p>

          <p>
            <span>
              If there are any questions regarding this Privacy Policy you may
              contact us using the information below.
            </span>
          </p>

          <p>
            <span>Email: </span>
            <span>
              <a href="mailto:eric@pleasantpicturesmusicclub.com">
                <span>e</span>
              </a>
              <a href="mailto:eric@pleasantpicturesmusicclub.com">
                <span>ric@pleasantpicturesmusicclub.com</span>
              </a>
            </span>
            <span> </span>
            <span></span>
          </p>

          <p>
            <span></span>
          </p>

          <p>
            <span>Updated: January 1, 2018.</span>
          </p>

          <p>
            <span></span>
          </p>
        </div>
      </div>
    </div>
  );
};
