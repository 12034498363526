import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';
import ContentPageLayout from '../../layouts/ContentPageLayout';
import { CustomTheme } from '../../types';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {},
    content: { marginTop: theme.spacing(3) },
    topRow: {
      display: 'flex',
      justifyContent: 'flexEnd',
      '& > *:first-child': {
        marginRight: theme.spacing(1),
      },
      [theme.breakpoints.only('xs')]: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
    },
  })
);

const POINTS = [
  {
    title: 'What comes with a subscription?',
    description:
      'The dopest songs in the internet are now in your creative arsenal! You can use your subscription for personal, private, and commercial purposes. All of our music is cleared to use on any platform so the world is your oyster!',
  },
  {
    title: 'Can I use my subscription on my client’s project?',
    description:
      'Absolutely! However, if you’re producing a movie or another project for a client (for personal or commercial use), the music licensed by you can only be used for that purpose. Your client does not own the license and, so, they cannot use it in any other work.',
  },
  {
    title: 'Can my business use my subscription?',
    description:
      'Yes! Just note that your employees, collaborators, and anyone else using your license must do so solely for your company’s productions. If they want to use music from our library for a project outside your company, they will need to purchase their own license for it.',
  },
  {
    title: 'Is the music royalty free?',
    description:
      'Our music is royalty free, meaning, you as a subscriber will never have to pay any ongoing royalties to PPMC for the use of our music. We like to keep it simple, you subscribe to our service and you use our music for as you like. If you are a broadcaster, streaming service, or a production company making content for either broadcast or streaming platforms you will need to fill out a cue sheet so that performance royalties can be paid to the proper entities. (BMI, ASCAP, SESAC). If you have any further questions about performance royalties here is a good reference.',
  },
  {
    title: 'Why subscription based service?',
    description:
      'A subscription based service is the best way we can deliver you the most value. This unlocks all the music we have to offer with one low price! You can turn off your auto renew anytime if you don’t want to renew after your year is up.',
  },
];

export interface FAQPageProps {}
export const FAQPage = ({}: FAQPageProps) => {
  const classes = useStyles();

  return (
    <ContentPageLayout title="FAQ">
      <div className={classes.container}>
        <div className={classes.topRow}>
          <Link to={AppRoutes.TERMS}>
            <Button variant="outlined">Terms of use</Button>
          </Link>
          <Link to={AppRoutes.POLICY}>
            <Button variant="outlined">Privacy Policy</Button>
          </Link>
        </div>
        <div className={classes.content}>
          {POINTS.map((point) => (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{point.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{point.description}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
    </ContentPageLayout>
  );
};
