import { makeStyles, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { useGetCategoriesApiCall } from '../../api/categories/hooks';
import { useGetPurchasedTracksApiCall } from '../../api/tracks/hooks';
import { useAudio } from '../../providers/AudioProvider';
import { useSession } from '../../providers/SessionProvider';
import { CustomTheme } from '../../types';
import { isUserSubscribed } from '../../utils/isUserSubscribed';
import {
  DownloadTrackButton,
  PurchaseTrackButton,
} from '../PurchaseButtons/PurchaseButtons';
import {
  FavoriteButton,
  ShareButton,
} from '../ShareFavoriteButtons/ShareFavoriteButtons';
import { AudioPlayerControls } from './AudioPlayerControls';
import { AudioProgress } from './AudioProgress';
import { AudioWaveformProgress } from './AudioWaveformProgress';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      minHeight: 80,
      background: theme.palette.primary.main,
      zIndex: 3,
      display: 'flex',
      flexDirection: 'column',
      '& > *:last-child': {
        flex: 1,
      },
    },
    trackDetailsRow: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      justifyContent: 'space-between',
      [theme.breakpoints.only('xs')]: {
        padding: theme.spacing(0, 0),
      },
      [theme.breakpoints.only('sm')]: {
        padding: theme.spacing(0, '5%'),
      },
    },
    title: {
      margin: theme.spacing(0, 2),
      [theme.breakpoints.only('xs')]: {
        '& > *': {
          fontSize: 14,
        },
      },
    },
    waveform: {
      flex: 1,
      height: 32,
      overflow: 'hidden',
      marginRight: theme.spacing(1),
      minWidth: 150,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    shareFavoriteButtons: {
      marginRight: theme.spacing(2),
      '& > *:first-child': {
        marginRight: theme.spacing(1),
      },
    },
    purchaseButtons: {
      whiteSpace: 'nowrap',
      '& > *:first-child': {
        marginRight: theme.spacing(1),
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    categoryImage: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      padding: theme.spacing(0.75),
      marginLeft: theme.spacing(2),
      overflow: 'hidden',
      // cursor: 'pointer',
      '& > img': {
        borderRadius: 8,
        maxWidth: '64px',
      },
    },
  })
);
export interface AudioPlayerProps {}

export const AudioPlayer = ({}: AudioPlayerProps) => {
  const classes = useStyles();
  const audio = useAudio();
  const session = useSession();
  const categoriesResult = useGetCategoriesApiCall();
  const purchasedTracksData = useGetPurchasedTracksApiCall({
    skip: !session.user,
  });
  const history = useHistory();

  const isSubscribed = Boolean(session) && isUserSubscribed(session);

  const purchased = useMemo(() => {
    if (audio.currentTrack && purchasedTracksData.data) {
      return Boolean(
        purchasedTracksData.data.find(
          (t) => t.Track._id === audio.currentTrack?._id
        )
      );
    } else {
      return false;
    }
  }, [audio.currentTrack, purchasedTracksData.data]);

  const selectedCategory = categoriesResult?.data?.find(
    (c) => c._id === audio.currentTrack?.categoryID
  );

  return audio.currentTrack ? (
    <div className={classes.container}>
      <AudioProgress />
      <div className={classes.trackDetailsRow}>
        <AudioPlayerControls />
        {selectedCategory && (
          <div
            className={classes.categoryImage}
            // onClick={() => history.push(`/?category=${selectedCategory._id}`)}
          >
            <img src={selectedCategory.detailImageURL} />
          </div>
        )}
        <div className={classes.title}>
          <Typography variant="body1">{audio.currentTrack.name}</Typography>
        </div>
        <div className={classes.waveform}>
          <AudioWaveformProgress showTime />
        </div>
        <div className={classes.shareFavoriteButtons}>
          <FavoriteButton track={audio.currentTrack} />
          <ShareButton track={audio.currentTrack} />
        </div>
        <div className={classes.purchaseButtons}>
          {/* {!isSubscribed && <EntireLibraryButton />} */}
          {!purchased && !isSubscribed && Boolean(audio.currentTrack) && (
            <PurchaseTrackButton color="secondary" track={audio.currentTrack} />
          )}
          {(purchased || isSubscribed) && Boolean(audio.currentTrack) && (
            <DownloadTrackButton
              track={audio.currentTrack}
              color={'secondary'}
              size={'large'}
            />
          )}
        </div>
      </div>
    </div>
  ) : null;
};
