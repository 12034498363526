import {
  checkoutPlan,
  checkoutPlanV2,
  checkoutTrack,
  checkoutTrackPack,
  checkoutTrackV2,
  getPaymentMethod,
} from '.';
import { useActionApiCall } from '../useActionApiCall';
import { useApiCall, UseApiOptions } from '../useApiCall';

export const useCheckoutPlanApiCall = (options?: UseApiOptions) => {
  const result = useActionApiCall({
    apiCall: checkoutPlan,
    ...options,
  });
  return result;
};

export const useCheckoutTrackPackApiCall = (options?: UseApiOptions) => {
  const result = useActionApiCall({
    apiCall: checkoutTrackPack,
    ...options,
  });
  return result;
};

export const useCheckoutTrackApiCall = (options?: UseApiOptions) => {
  const result = useActionApiCall({
    apiCall: checkoutTrack,
    ...options,
  });
  return result;
};

export const useGetPaymentMethod = (options?: UseApiOptions) => {
  const result = useApiCall({
    variables: {},
    apiCall: getPaymentMethod,
    ...options,
  });
  return result;
};

export const useCheckoutPlanV2ApiCall = (options?: UseApiOptions) => {
  const result = useActionApiCall({
    apiCall: checkoutPlanV2,
    ...options,
  });
  return result;
};
