import {
  CircularProgress,
  Grid,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Category } from '../../api/categories';
import { Plan, PlanLevel } from '../../api/plans';
import { useGetPlansApiCall } from '../../api/plans/hooks';
import { CreateUserBody } from '../../api/users';
import { useSession } from '../../providers/SessionProvider';
import { CustomTheme } from '../../types';
import SignupForm from '../Auth/SignupForm/SignupForm';
import { PageLoadingSpinner } from '../PageLoadingSpinner/PageLoadingSpinner';
import { BuyNowCheckout } from './BuyNowCheckout';
import { PlanSelector } from './PlanSelector';
import { findValidPlans } from './utils/findValidPlans';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {
      padding: theme.spacing(1.5, '10%'),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1.5, '5%'),
      },
    },
    signupForm: {
      paddingRight: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    rowContainer: {
      display: 'flex',
      '& > *': {
        flex: 1,
      },
      '& > *:first-child': {
        borderRight: `1px solid #484143`,
        [theme.breakpoints.down('sm')]: {
          borderRight: 'none',
        },
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
      paddingBottom: theme.spacing(3),
    },
    selectSection: {
      paddingRight: '5%',
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
      },
    },
    checkoutSection: {
      paddingLeft: '5%',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        marginTop: theme.spacing(2),
        paddingTop: theme.spacing(2),
        borderTop: `1px solid ${theme.palette.borders.light}`,
      },
    },
    bulletsContainer: {
      borderTop: `1px solid #484143`,
      padding: theme.spacing(3),
      paddingLeft: 0,
      '& > ul': {
        maxWidth: 450,
        paddingLeft: theme.spacing(2),
      },
      '& p': {
        fontSize: 14,
      },
    },
    caption: {
      marginTop: theme.spacing(2),
    },
  })
);
export interface BuyNowPageProps {}

export const BuyNowPage = ({}: BuyNowPageProps) => {
  const classes = useStyles();
  const [signupFormData, setSignupFormData] = useState<CreateUserBody | null>(
    null
  );
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const plansResult = useGetPlansApiCall();
  const { yearlyPlan, monthlyPlan } = useMemo(
    () => findValidPlans(plansResult.data || []),
    [plansResult.data]
  );
  const session = useSession();

  useEffect(() => {
    if (plansResult.data && selectedPlan === null) {
      const { yearlyPlan } = findValidPlans(plansResult.data);
      if (yearlyPlan) {
        setSelectedPlan(yearlyPlan);
      }
    }
  }, [plansResult.data]);

  return plansResult.loading ? (
    <PageLoadingSpinner />
  ) : (
    <div className={classes.container}>
      <div className={classes.rowContainer}>
        <div className={classes.selectSection}>
          {Boolean(plansResult.data) && (
            <PlanSelector
              onSelect={setSelectedPlan}
              {...{ yearlyPlan, monthlyPlan }}
              selectedPlanId={selectedPlan?.id}
            />
          )}
          {!Boolean(session.user) && (
            <div className={classes.signupForm}>
              <SignupForm
                onChange={(formData) => setSignupFormData(formData)}
                showSubmitButton={false}
              />
            </div>
          )}
          <Typography variant="caption" className={classes.caption}>
            *Do you already subscribe to another music library? Email us proof
            of subscription and we will give you 50% off a subscription here at
            Pleasant Pictures Music Club!{' '}
            <Link href="mailto:info@pleasantpictures.club">
              info@pleasantpictures.club
            </Link>
          </Typography>
        </div>
        <div className={classes.checkoutSection}>
          {selectedPlan && (
            <BuyNowCheckout
              plan={selectedPlan}
              signupFormData={signupFormData}
            />
          )}
        </div>
      </div>
      <div className={classes.bulletsContainer}>
        <ul>
          <li>
            <Typography variant="body2">
              Save TIME and MONEY by quickly finding your perfect track
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              Have Unlimited&nbsp;Access to ALL music in our library
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              Have confidence in your work for using the best music
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              All tracks are pre-edited with multiple length options so you can
              create faster
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              Money Back Guarantee for 30 days no questions asked
            </Typography>
          </li>
        </ul>
      </div>
    </div>
  );
};
