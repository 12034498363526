import {
  Button,
  CircularProgress,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useGetCategoryTracksApiCall } from '../../api/categories/hooks';
import { CheckoutTrackPackBody } from '../../api/checkout';
import { useCheckoutTrackPackApiCall } from '../../api/checkout/hooks';
import { Coupon } from '../../api/coupons';
import { useGetSiteConfigsApiCall } from '../../api/siteConfig/hooks';
import { useGetTracksApiCall } from '../../api/tracks/hooks';
import { AppRoutes } from '../../constants/routes';
import { MUSIC_PACK_PRICE } from '../../constants/values';
import { useCheckoutSignup } from '../../hooks/useCheckoutSignup';
import ContentPageLayout from '../../layouts/ContentPageLayout';
import { usePurchasedTracks } from '../../providers/PurchasedTracksProvider';
import { useSession } from '../../providers/SessionProvider';
import { CustomTheme } from '../../types';
import { recordPurchase } from '../../utils/affiliateProgram';
import { AnalyticsService } from '../../utils/analytics';
import { applyCouponToAmount } from '../../utils/applyCouponToAmount';
import SignupForm from '../Auth/SignupForm/SignupForm';
import { CheckoutFields } from '../CheckoutFields/CheckoutFields';
import { LoginSignup } from '../LoginSignup/LoginSignup';
import { PageLoadingSpinner } from '../PageLoadingSpinner/PageLoadingSpinner';
import { TrackList } from '../TrackList/TrackList';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > *': {
        marginTop: theme.spacing(3),
      },
      '& > *:first-child': {
        marginTop: theme.spacing(0),
      },
    },
    checkoutFields: {
      maxWidth: 800,
      width: '100%',
    },
    children: {
      maxWidth: 800,
    },
    loginSignup: {
      maxWidth: 500,
    },
    image: {
      width: '100%',
      maxWidth: 1000,
      textAlign: 'center',
      '& img': {
        width: '100%',
      },
    },
    form: {
      whiteSpace: 'nowrap',
      maxWidth: 500,
      [theme.breakpoints.only('xs')]: {
        whiteSpace: 'initial',
      },
    },
    buyButton: {
      margin: theme.spacing(3, 0),
    },
  })
);
export interface MusicPackPageProps {
  collectionName:
    | 'highEndMusicCollectionID'
    | 'stockMusicProBundleCollectionID'
    | 'videographerCollectionID';
  image: JSX.Element | Element;
  children: string | JSX.Element;
  hideBuySection?: boolean;
}

export const MusicPackPage = ({
  collectionName,
  image,
  children,
  hideBuySection,
}: MusicPackPageProps) => {
  const classes = useStyles();
  const siteConfig = useGetSiteConfigsApiCall();
  const packCollection = siteConfig?.data?.[0].info[collectionName];
  const [code, setCode] = useState<string>('');
  const [showSignup, setShowSignup] = useState<boolean>(false);
  const [showCheckout, setShowCheckout] = useState<boolean>(false);
  const tracks = useGetCategoryTracksApiCall(
    { _id: packCollection as number },
    { skip: !Boolean(siteConfig?.data?.[0].info[collectionName]) }
  );
  const { refetch } = usePurchasedTracks();
  const checkoutTrackPack = useCheckoutTrackPackApiCall();
  const history = useHistory();
  const handleCheckoutSuccess = ({ totalPaid }: { totalPaid: number }) => {
    if (!session.user) {
      return;
    }
    recordPurchase({
      customer: { email: session.user.email },
      total: MUSIC_PACK_PRICE,
      id: `TRACKPACK:${collectionName}:USER:${session.user?._id}`,
    });
    refetch();
    history.push(AppRoutes.CHECKOUT_TRACK_PACK_SUCCESS);
    AnalyticsService().packPurchase(collectionName);
  };
  const checkout = useCheckoutSignup<CheckoutTrackPackBody & { token: string }>(
    {
      baseAmount: MUSIC_PACK_PRICE * 100,
      runApiCall: checkoutTrackPack.runApiCall,
      onSuccess: handleCheckoutSuccess,
    }
  );

  const session = useSession();

  const handleSubmitCode = async (e: FormEvent) => {
    e.preventDefault();
    if (!session.user) {
      setShowSignup(true);
    } else {
      try {
        await checkoutTrackPack.runApiCall({ packID: collectionName, code });
        AnalyticsService().packCodeRedemption(collectionName);
        handleCheckoutSuccess({ totalPaid: 0 });
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setCode(e.target.value);

  const handleAuthComplete = () => {
    setShowSignup(false);
  };

  const handleSelectBuy = () => {
    if (!session.user) {
      setShowSignup(true);
    } else {
      setShowCheckout(true);
    }
  };

  return (
    <ContentPageLayout>
      <div className={classes.container}>
        <div className={classes.image}>{image}</div>

        <form onSubmit={handleSubmitCode} className={classes.form}>
          <TextField
            name="code"
            label="Redeem download code"
            fullWidth
            onChange={handleChange}
            error={Boolean(checkoutTrackPack.error)}
            helperText={checkoutTrackPack.error ? 'Invalid Code' : ''}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={checkoutTrackPack.loading}
          >
            Redeem
          </Button>
        </form>
        <Typography align="center" variant="body2" className={classes.children}>
          {children}
        </Typography>

        {showSignup ? (
          <div className={classes.loginSignup}>
            <Typography variant="h6">
              An account is required to continue
            </Typography>
            <LoginSignup onComplete={handleAuthComplete} />
          </div>
        ) : showCheckout && hideBuySection !== true ? (
          <div className={classes.checkoutFields}>
            <CheckoutFields
              hideCouponInput={true}
              amountWithDiscount={checkout.amountWithDiscount}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                checkout.completeCheckout({ packID: collectionName })
              }
            >
              Checkout
            </Button>
          </div>
        ) : (
          hideBuySection !== true && (
            <div className={classes.buyButton}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSelectBuy}
                disabled={checkoutTrackPack.loading}
              >
                Buy the pack for ${MUSIC_PACK_PRICE}
              </Button>
            </div>
          )
        )}

        {siteConfig.loading || tracks.loading === undefined ? (
          <PageLoadingSpinner />
        ) : (
          tracks.data && <TrackList tracks={tracks.data} />
        )}
      </div>
    </ContentPageLayout>
  );
};
