import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  FormControl,
  MenuItem,
  FormHelperText,
  Select,
  Grid,
} from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { META_SUBSCRIPTION_TYPES } from '../../../../api/users';
import { useUpdateUserApiCall } from '../../../../api/users/hooks';

const useStyles = makeStyles((theme) =>
  createStyles({
    labels: {
      marginLeft: theme.spacing(1),
    },
    subscriptionSelect: {
      width: '20vw',
      textAlign: 'left',
    },
    datePicker: {
      margin: theme.spacing(4, 0),
      width: '20vw',
    },
  })
);

const convertToDefinedEventParams = ({
  name,
  value,
}: {
  name: string;
  value: any;
}) => ({
  target: {
    name,
    value,
  },
});
interface EditUserModalProps {
  userName?: string;
  subscriptionType?: string;
  subscriptionStartDate?: string;
  subscriptionEndDate?: string;
  modalOpen: boolean;
  onCancel: VoidFunction;
  onEditUserInfo: VoidFunction;
  userID?: number;
}
interface EditUserVariables {
  typeOfSubscription?: string;
  startDateOfSubscription: any;
  endDateOfSubscription: any;
}

export default function EditUserModal({
  userName,
  subscriptionType,
  subscriptionStartDate,
  subscriptionEndDate,
  modalOpen,
  onCancel,
  onEditUserInfo,
  userID,
}: EditUserModalProps) {
  const classes = useStyles();
  const updateUser = useUpdateUserApiCall();
  const [
    selectedEditUserVariables,
    setSelectedEditUserVariables,
  ] = useState<EditUserVariables>({
    typeOfSubscription: subscriptionType,
    startDateOfSubscription: new Date(
      subscriptionStartDate ? subscriptionStartDate : ''
    ),
    endDateOfSubscription: new Date(
      subscriptionEndDate ? subscriptionEndDate : ''
    ),
  });

  const minDate = selectedEditUserVariables.startDateOfSubscription;
  const maxDate = selectedEditUserVariables.endDateOfSubscription;

  const handleEditUserVariablesChange = (e: any) => {
    setSelectedEditUserVariables({
      ...selectedEditUserVariables,
      [e.target.name]: e.target.value,
    });
  };
  const handleClickCancel = () => {
    onEditUserInfo();
    onCancel();
  };
  const handleUpdateUserInfo = async () => {
    await updateUser.runApiCall({
      _id: userID,
      subscription_type: selectedEditUserVariables.typeOfSubscription,
      subscription_start_date: selectedEditUserVariables.startDateOfSubscription?.toString(),
      subscription_end_date: selectedEditUserVariables.endDateOfSubscription?.toString(),
    });
    onEditUserInfo();
    onCancel();
  };

  return (
    <>
      <Dialog open={modalOpen} onClose={handleClickCancel}>
        <DialogTitle>{userName}</DialogTitle>
        <DialogContent dividers>
          <Grid container direction="column" justify="space-between">
            <Grid item xs={3}>
              <FormControl>
                <FormHelperText className={classes.labels}>
                  Subscription Type
                </FormHelperText>
                <Select
                  name="typeOfSubscription"
                  defaultValue={selectedEditUserVariables.typeOfSubscription}
                  variant="outlined"
                  className={classes.subscriptionSelect}
                  onChange={handleEditUserVariablesChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {META_SUBSCRIPTION_TYPES.map((subscriptionType: string) => (
                    <MenuItem value={subscriptionType}>
                      {subscriptionType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={3}>
                <KeyboardDatePicker
                  className={classes.datePicker}
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  maxDate={maxDate}
                  margin="normal"
                  label="Subscription Start Date"
                  value={selectedEditUserVariables.startDateOfSubscription}
                  invalidDateMessage="You must select a subscription start date"
                  onChange={(date) =>
                    handleEditUserVariablesChange(
                      convertToDefinedEventParams({
                        name: 'startDateOfSubscription',
                        value: date,
                      })
                    )
                  }
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <KeyboardDatePicker
                  className={classes.datePicker}
                  inputVariant="outlined"
                  minDate={minDate}
                  format="MM/dd/yyyy"
                  margin="normal"
                  label="Subscription End Date"
                  value={selectedEditUserVariables.endDateOfSubscription}
                  invalidDateMessage="You must select a subscription end date"
                  onChange={(date) =>
                    handleEditUserVariablesChange(
                      convertToDefinedEventParams({
                        name: 'endDateOfSubscription',
                        value: date,
                      })
                    )
                  }
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateUserInfo}
            disabled={
              selectedEditUserVariables.startDateOfSubscription ==
                'Invalid Date' ||
              selectedEditUserVariables.startDateOfSubscription == null ||
              selectedEditUserVariables.endDateOfSubscription ==
                'Invalid Date' ||
              selectedEditUserVariables.endDateOfSubscription == null
            }
            autoFocus
          >
            Update User Info
          </Button>
          <Button color="primary" onClick={handleClickCancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
