import { createMuiTheme } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import { CustomTheme } from './types';
const theme = createMuiTheme({
  palette: {
    borders: { light: '#DDDDDD', dark: '#565656' },
    primary: { main: '#000000', contrastText: '#3a3939' },
    secondary: { main: '#FF5001' },
    tertiary: { main: '#D8D8D8' },
    background: {
      paper: '#101010',
      default: '#231F20',
      contrast: '#424242',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#A2A2A2',
      disabled: '',
    },
    divider: 'rgba(255, 255, 255, 0.12)',
    action: {
      active: '#fff',
      hover: 'rgba(255, 255, 255, 0.08)',
      selected: 'rgba(255, 255, 255, 0.16)',
      disabled: 'rgba(255, 255, 255, 0.3)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
    },
  },
  typography: {
    fontFamily: 'UntitledSans',
    body1: {
      fontSize: 12,
    },
    body2: {
      fontSize: 16,
    },
    subtitle1: {
      fontSize: 15,
      color: '#686868',
    },
    subtitle2: {
      fontSize: 15,
      fontWeight: 'bold',
    },
    h1: {
      fontWeight: 'bold',
    },
    h2: {
      fontWeight: 'bold',
    },
    h3: {
      fontWeight: 'bold',
    },
    h4: {
      fontWeight: 'bold',
    },
    h5: {
      fontWeight: 'bold',
    },
    h6: {
      fontWeight: 'bold',
    },
  },
  props: {
    MuiTextField: {
      InputLabelProps: {
        shrink: true,
      },
    },
    MuiChip: {
      deleteIcon: <Close />,
    },
  },
  overrides: {
    MuiChip: {
      root: {
        margin: 8,
        borderRadius: 8,
        padding: 12,
      },
      colorPrimary: {
        color: '#FFFFFF',
        backgroundColor: '#484143',
      },
      deleteIcon: {
        margin: '0px 0px 0px 0px',
        color: '#000000',
        width: 16,
      },
    },
    MuiTextField: {
      root: {
        marginTop: 16,
      },
    },
    MuiLink: {
      root: {
        textDecoration: 'underline',
        color: '#337ab7',
      },
    },
    MuiInputLabel: {
      root: {
        color: 'white',
      },
      focused: {
        color: 'white',
      },
    },
    MuiOutlinedInput: {
      root: {
        color: 'white',
        borderColor: 'white',
        '"&:hover:not($disabled):not($focused):not($error) $notchedOutline"': {
          borderColor: 'white',
        },
        '& .Mui-focused fieldset': {
          borderColor: 'white',
          color: 'white',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'white',
        },
        '& .MuiOutlinedInput-notchedOutline:hover': {
          borderColor: 'white',
        },
      } as any,
    },
    MuiInput: {
      root: {
        color: 'white',
        // borderColor: 'white',
        // '"&:hover:not($disabled):not($focused):not($error)"': {
        //   borderColor: 'white',
        // },
        // '& .Mui-focused fieldset': {
        //   borderColor: 'white',
        //   color: 'white',
        // },
        // '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        //   borderBottomColor: 'white !important',
        // },
        '&:before': {
          borderBottom: '1px solid #ababab !important',
        },
      } as any,
      // underline: {
      //   '&:after &:before': {
      //     color: 'white',
      //     borderBottom: '1px solid white',
      //   },
      // } as any,
    },
    MuiButton: {
      root: {
        borderRadius: 8,
        marginTop: 8,
        marginBottom: 8,
        minWidth: 100,
      },
      label: {
        textTransform: 'none',
      },
      sizeLarge: {
        minWidth: 145,
      },
      containedPrimary: {
        color: 'white',
      },
    },

    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiFormLabel: {
      root: {
        fontWeight: 'bold',
        color: 'white',
        '&$focused': {
          color: 'white',
          fontWeight: 'bold',
        },
      } as any,
      focused: {
        color: 'white',
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none',
      },
    },
  },
} as CustomTheme);

export default theme;
