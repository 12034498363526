import { Button, makeStyles, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';
import { useIsMobile } from '../../hooks/isMobile';
import ContentPageLayout from '../../layouts/ContentPageLayout';
import HeaderImageLayout from '../../layouts/HeaderImageLayout';
import { CustomTheme } from '../../types';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    imageSection: {
      '& > img': { width: '100%' },
      '&>*:last-child': {
        padding: theme.spacing(3),
      },
      background: '#101010',
    },
    aboutContainer: { '&>*': { marginBottom: theme.spacing(4) } },
    container: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    containerRow: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: theme.spacing(4, 0),
      [theme.breakpoints.up('md')]: {
        '& > *': {
          margin: theme.spacing(0, 3),
        },
        '& > *:first-child': {
          marginLeft: 0,
        },
        '& > *:last-child': {
          marginRight: 0,
        },
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        '& > *': {
          margin: theme.spacing(2, 0),
        },
      },
    },
    divider: { borderTop: `1px solid #565656`, margin: theme.spacing(3, 0) },
    description: {
      padding: theme.spacing(3),
      fontSize: 28,
      [theme.breakpoints.down('sm')]: { fontSize: 20 },
      borderRight: `8px solid ${theme.palette.common.white}`,
      marginRight: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        width: 'initial',
        marginRight: 'initial',
        borderRight: 'none',
      },
    },
    sectionContent: {},
    sectionContainer: {},

    title: { margin: theme.spacing(2, 0) },
  })
);
export interface AboutUsPageProps {}

export const AboutUsSection = ({
  children,
  title,
}: {
  children: string;
  title: string | JSX.Element;
}) => {
  const classes = useStyles();
  return (
    <div className={classes.sectionContainer}>
      <div className={classes.title}>
        <Typography variant="body2">{title}</Typography>
      </div>
      <div className={classes.sectionContent}>
        <Typography variant="body2">{children}</Typography>
      </div>
    </div>
  );
};

export const AboutUsPage = ({}: AboutUsPageProps) => {
  const classes = useStyles();
  const history = useHistory();
  const isMobile = useIsMobile();

  return (
    <HeaderImageLayout
      title="About us"
      imgSrc="https://s3-us-west-2.amazonaws.com/pleasantpictures-images/BIO_PHOTO.jpg"
      action={
        <Button
          variant="contained"
          color="secondary"
          size={isMobile ? 'small' : 'large'}
          onClick={() => history.push(AppRoutes.LANDING)}
        >
          Browse Music
        </Button>
      }
    >
      <div className={classes.container}>
        <Typography variant="body2" className={classes.description}>
          We are a unique little company. After years of composing for other
          large libraries, we decided to channel our creative juices to one
          intentional and beautiful place, Pleasant Pictures Music Club. <br />
          <br /> As seasoned composers we keep our ear to the ground to see what
          videographers/filmmakers would LOVE to use in their projects. We then
          get to work stacking our library with music we LOVE making.
          <br /> <br /> Welcome to the library with zero fluff.
        </Typography>
        <div className={classes.aboutContainer}>
          <AboutUsSection title={'Zero Fluff'}>
            PPMC is stacked with carefully curated tracks that you’d want to put
            on your own playlist. You won’t waste time looking through fluff—we
            don’t have any.
          </AboutUsSection>
          <AboutUsSection title={'Always Keeps It Fresh'}>
            By joining our club you will not only have access to our collection
            whenever you need it, but we are consistently working on new content
            and anticipate what you will need in the future.
          </AboutUsSection>
          <AboutUsSection title={'Edits for Days'}>
            We understand how the world works and have created 90, 60, 30, 15
            and 6 second edits in both WAV and MP3 for EVERY song so you can
            drag and drop our tracks into your projects.
          </AboutUsSection>
        </div>
      </div>
      <div className={classes.divider}></div>
      <Typography variant="h5">Meet the Team</Typography>
      <div className={classes.containerRow}>
        <div className={classes.imageSection}>
          <img src="https://pleasantpictures-images.s3.us-west-2.amazonaws.com/stu2021.png" />
          <AboutUsSection title={'Stuart Maxfield'}>
            Stuart Maxfield is a songwriter, composer, and performing artist in
            Fictionist and S2_COOL. Fictionist has toured with Neon Trees,
            Imagine Dragons, Coin, Walk the Moon and Vampire Weekend. S2_COOL
            has featured with Kaskade, and Mr. Tape. Stuart has composed for
            Apple, MTV, NFL, NHL, Syfy, Univision, History, Discovery France,
            Lifetime, HGTV and international film. He lives with his wife and
            two sons in Springville, Utah.
          </AboutUsSection>
        </div>
        <div className={classes.imageSection}>
          <img src="https://pleasantpictures-images.s3.us-west-2.amazonaws.com/eric2021.png" />
          <AboutUsSection title={'Eric Robertson'}>
            Eric Robertson is a Billboard charting producer and composer. His
            main passion is composing for motion picture and documentaries. He
            has composed for Tesla, Lagoon, Reebok, Studio C, West Elm,
            Digitech, Wendy’s, and network television. His band New Shack has
            had placements on MTV and Bravo. He’s an avid collector of vintage
            analog synths and resides in Provo, Utah with his hot wife and three
            kids.
          </AboutUsSection>
        </div>
      </div>
    </HeaderImageLayout>
  );
};
