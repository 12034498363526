import { ContactSupportOutlined } from '@material-ui/icons';

export const recordPurchase = ({
  id,
  total,
  customer,
}: {
  id: string;
  total: number;
  customer: { email: string | undefined };
}) => {
  const goaffproOrder = {
    number: id,
    total,
    customer,
  };
  if (
    (window as any).goaffproTrackConversion &&
    process.env.NODE_ENV !== 'development'
  ) {
    (window as any).goaffproTrackConversion(goaffproOrder);
  }
};
