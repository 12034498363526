import { Button, makeStyles, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import { useSession } from '../../providers/SessionProvider';
import { CustomTheme } from '../../types';
import { EntireLibraryButton } from '../PurchaseButtons/PurchaseButtons';
import { format } from 'date-fns';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {},
    content: {},
  })
);
export interface SubscriptionSettingsProps {}
const FORMAT = 'MM/dd/yyyy';

export const SubscriptionSettings = ({}: SubscriptionSettingsProps) => {
  const classes = useStyles();
  const session = useSession();

  return (
    <div className={classes.container}>
      {session?.user?.subscription_start_date === null ? (
        <EntireLibraryButton />
      ) : (
        <div className={classes.content}>
          <Typography>
            Start Date:{' '}
            {session?.user?.subscription_start_date
              ? format(new Date(session?.user?.subscription_start_date), FORMAT)
              : null}
          </Typography>
          <Typography>
            End Date:{' '}
            {session?.user?.subscription_end_date
              ? format(new Date(session?.user?.subscription_end_date), FORMAT)
              : null}
          </Typography>
        </div>
      )}
    </div>
  );
};
