import { Button, makeStyles, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';
import { CustomTheme } from '../../types';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {
      textAlign: 'center',
      '& > *': { marginTop: theme.spacing(2) },
    },
  })
);
export interface CheckoutSuccessProps {}

export const CheckoutSuccess = ({}: CheckoutSuccessProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h4">
        You have been successfully subscribed
      </Typography>
      <Link to={AppRoutes.LANDING}>
        <Button color="primary" variant="contained">
          Take me to the library
        </Button>
      </Link>
    </div>
  );
};
