import {
  makeStyles,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { Coupon } from '../../api/coupons';
import { Plan } from '../../api/plans';
import { CreateUserBody } from '../../api/users';
import { useSession } from '../../providers/SessionProvider';
import { CustomTheme } from '../../types';
import { applyCouponToAmount } from '../../utils/applyCouponToAmount';
import { getPlanAmountString } from './utils/getPlanAmountString';
import {
  useCheckoutPlanApiCall,
  useCheckoutPlanV2ApiCall,
} from '../../api/checkout/hooks';
import { useHistory } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';
import { getPrettyCurrency } from '../../utils/getPrettyCurrencty';
import { useCheckoutSignup } from '../../hooks/useCheckoutSignup';
import { CheckoutFields } from '../CheckoutFields/CheckoutFields';
import { AnalyticsService } from '../../utils/analytics';
import { recordPurchase } from '../../utils/affiliateProgram';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    submit: {
      marginTop: theme.spacing(3),
    },
    container: {
      '& > *:last-child': {
        marginTop: theme.spacing(2),
      },
    },
    description: {
      fontSize: 14,
      marginTop: theme.spacing(2),
    },
    subtitle: { margin: theme.spacing(3, 0) },
  })
);
export interface BuyNowCheckoutProps {
  plan: Plan;
  signupFormData?: CreateUserBody | null;
}

export const BuyNowCheckout = ({
  plan,
  signupFormData,
}: BuyNowCheckoutProps) => {
  const classes = useStyles();
  const session = useSession();
  const [coupon, setCoupon] = useState<Coupon | null>(null);
  const subscribeResult = useCheckoutPlanV2ApiCall();
  const history = useHistory();

  const handleCheckoutSuccess = async () => {
    AnalyticsService().subscriptionCreated(plan.amount);
    if (!session.user) {
      return;
    }
    recordPurchase({
      customer: { email: session.user.email },
      total: coupon
        ? applyCouponToAmount(plan.amount / 100, coupon)
        : plan.amount / 100,
      id: `PLAN:${plan.id}:USER:${session.user?._id}`,
    });
    await session.refetch();
    history.push(AppRoutes.CHECKOUT_SUCCESS);
  };

  const checkout = useCheckoutSignup({
    coupon,
    baseAmount: plan.amount,
    runApiCall: subscribeResult.runApiCall,
    signupFormData,
    onSuccess: handleCheckoutSuccess,
  });

  const handleSubmit = async () => {
    checkout.completeCheckout({ planID: plan.id });
  };

  return (
    <div className={classes.container}>
      <Typography variant="body2" className={classes.subtitle}>
        Order Summary
      </Typography>
      <Typography variant="h5">{getPlanAmountString(plan)}</Typography>
      <div className={classes.description}>
        <Typography component="div">Unlimited use license.</Typography>
        <Typography component="div">1 year subscription plan.</Typography>
        <Typography component="div">
          Unlimited downloads from the entire PPMC catalog.
        </Typography>
      </div>
      <CheckoutFields
        onCoupon={setCoupon}
        hideCardInput={checkout.amountWithDiscount === 0}
        amountWithDiscount={checkout.amountWithDiscount}
      />
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        onClick={handleSubmit}
        disabled={checkout.loading}
        className={classes.submit}
        size="large"
      >
        {checkout.loading ? <CircularProgress /> : 'Add to Cart'}
      </Button>
      <Typography variant="caption" color="error">
        {checkout.error}
      </Typography>
    </div>
  );
};
