import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Paper,
} from '@material-ui/core';

import { Tag } from '../../../../api/tags';
import TagListItem from './TagListItem';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      textAlign: 'left',
      padding: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
    mainContent: { background: '#D8D8D8' },
    button: {
      display: 'flex',
      margin: theme.spacing(1),
      marginLeft: theme.spacing(4),
    },
    deleteButton: {
      display: 'flex',
      marginLeft: theme.spacing(2.5),
    },
    table: {},
    tableHeader: { fontWeight: 'bold' },
    tableRow: { background: theme.palette.background.default },
  })
);

interface TagListProps {
  tags: Tag[];
  onDeleteTag: VoidFunction;
  onUpdateTag: VoidFunction;
  dataLoaded: boolean;
}

export default function TagsList({
  tags,
  onUpdateTag,
  onDeleteTag,
  dataLoaded,
}: TagListProps) {
  const classes = useStyles();
  return (
    <div className={classes.mainContent}>
      {dataLoaded ? (
        <CircularProgress />
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeader}>
                    Tag Name
                  </TableCell>
                  <TableCell className={classes.tableHeader} align="left">
                    Category
                  </TableCell>
                  <TableCell className={classes.tableHeader} align="center">
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tags.map((tag) => (
                  <TagListItem
                    tag={tag}
                    onUpdateTag={onUpdateTag}
                    onDeleteTag={onDeleteTag}
                    key={tag._id}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
}
