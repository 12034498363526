import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import { CustomTheme } from '../../types';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {},
  })
);
export interface TermsProps {}

export const Terms = ({}: TermsProps) => {
  const classes = useStyles();

  return (
    <div>
      <div className="terms-section container">
        <p className="c22 c32">
          <span className="c3">
            Terms and Conditions - Pleasant Pictures Music Club
          </span>
        </p>
        <p className="c15">
          <span className="c3"></span>
        </p>
        <p className="c22 c32">
          <span className="c3">Updated: January 1, 2018</span>
        </p>
        <p className="c15">
          <span className="c3"></span>
        </p>
        <p className="c18">
          <span className="c19">
            Thanks for being here. By visiting our website, you are agreeing to
            these Terms and Conditions. Any specific licensing agreements for
            the purchase of music or music subscriptions will supercede any
            agreements here where applicable. If you have any questions about
            your rights under either, please reach out to us at{' '}
          </span>
          <span className="c19 c32 c33">
            <a className="c11" href="mailto:eric@pleasantpicturesmusicclub.com">
              eric@pleasantpicturesmusicclub.com
            </a>
          </span>
          <span className="c19 c32 c36">. </span>
        </p>
        <p className="c18">
          <span className="c1">&nbsp;OVERVIEW</span>
        </p>
        <p className="c18">
          <span className="c3">
            The terms &ldquo;we&rdquo;, &ldquo;us&rdquo;, &ldquo;our&rdquo;,
            &nbsp;&ldquo;Pleasant Pictures Music Club&rdquo; and
            &ldquo;PPMC&rdquo; refers to PLEASANTPICTURES.CLUB or Pleasant
            Pictures Music Club, &nbsp;as operated by PLEASANT PICTURES LIBRARY
            LLC, a Utah limited liability company. The term the
            &ldquo;Site&rdquo; refers to PLEASANTPICTURES.CLUB and all
            associated websites connected with PLEASANTPICTURES.CLUB. The term
            &ldquo;user,&rdquo; &ldquo;you&rdquo; and &ldquo;your&rdquo; refers
            to site visitors, customers, and any other users of the site or
            services.
          </span>
        </p>
        <p className="c18">
          <span className="c3">
            PLEASANT PICTURES LIBRARY LLC provides a website where customers can
            purchase music through subscriptions and individual track licensing
            agreements, with related physical and digital products (the
            &ldquo;Service&rdquo;).
          </span>
        </p>
        <p className="c18">
          <span className="c3">
            Use of PLEASANTPICTURES.CLUB, including access to all musical works
            available here and all online services provided by
            PLEASANTPICTURES.CLUB, is subject to the following Terms and
            Conditions. These Terms and Conditions apply to all site visitors,
            customers, and all other users of the site. By using the Site or
            Service and/or ordering a product from the Site, you agree to these
            Terms and Conditions, without modification, and acknowledge reading
            them. If you do not agree to these terms, you should stop using the
            Site and Service immediately.
          </span>
        </p>
        <p className="c18">
          <span className="c1">CHANGES TO TERMS</span>
        </p>
        <p className="c18">
          <span className="c3">
            We may amend these Terms at any time in our sole discretion. If we
            do so, we will post the modified Terms on the Service on the
            website. The modifications will be effective no earlier than
            fourteen (14) days after such modified Terms are posted, except that
            changes addressing new functions of the Service or changes made for
            legal reasons will be effective immediately. You agree to review
            these Terms periodically so that you are aware of any modifications.
            Your continued use of the Service after such modifications
            constitutes your acknowledgment of, and agreement to be bound by,
            the amended Terms.
          </span>
        </p>
        <p className="c18">
          <span className="c19 c27 c28">
            The Lowdown: We are super stoked that you are using
            PleasantPictures.club! By using our site, you agree to our terms. We
            might change our Terms periodically and we will post the updates
            here. You&rsquo;re responsible for keeping up to date.
          </span>
        </p>
        <p className="c18">
          <span className="c1">USE OF THE SITE + SERVICE</span>
        </p>
        <p className="c18">
          <span className="c3">
            To access or use the Site, you must be 18 years or older and have
            the requisite power and authority to enter into these Terms and
            Conditions. Individuals under the age of 18 are prohibited from
            using the Site. Please review our Privacy Notice, which governs your
            use of our Service.
          </span>
        </p>
        <p className="c9">
          <span className="c2 c5 c17">AVAILABILITY</span>
        </p>
        <p className="c9">
          <span className="c0">
            PPMC makes all possible efforts for the songs to be available at all
            times. However, sometimes mistakes happen. If we have any problems
            or issues with particular songs or services, we will communicate
            through notices on our website and/or the email account your
            provided on registration. PPMC may stop licensing certain songs at
            its sole discretion. In the unlikely event of a copyright
            infringement claim or dispute, PPMC may require user to stop using a
            song and remove it from its machines or files. In that highly
            unlikely event, PPMC shall provide you with reasonably comparable
            content free of charge, subject to the terms and conditions of this
            Agreement
          </span>
        </p>
        <p className="c9">
          <span className="c19 c27 c17">
            Lowdown: Stuff happens. We will do our best to fix it and we
            appreciate your patience.
          </span>
        </p>
        <p className="c25 c32 c40">
          <span className="c3">COPYRIGHT</span>
        </p>
        <p className="c12">
          <span className="c3">
            All content included in or made available through the Site or
            Service, such as text, graphics, logos, button icons, images, audio
            clips, songs, digital downloads, data compilations, the design and
            layout of the website and its elements, and any proprietary software
            is the property of PPMC or its content suppliers and protected by
            United States and international copyright laws.
          </span>
        </p>
        <p className="c12">
          <span className="c19 c27 c28">
            Lowdown: The stuff on our site is ours. Please do not steal or
            improperly use content that belongs to us or our users. We respect
            IP rights and you should, too. Not all sharing is caring.
          </span>
        </p>
        <p className="c18">
          <span className="c1">
            LICENSE AND ACCESS: RIGHTS INCLUDED IN SUBSCRIPTION AGREEMENT
          </span>
        </p>
        <p className="c18">
          <span className="c3">
            Nothing in this Agreement shall give you any right, title, or
            interest to the songs, other than the right to use the Works in
            accordance with the applicable licensing agreement upon purchase.
            &nbsp;So you can be aware, PPMC grants these non-exclusive
            mechanical, synchronization, and performance rights to you during
            the purchase term of a subscription license, including the right to:
          </span>
        </p>
        <ol className="c34 lst-kix_swtwo7yy7guw-1 start">
          <li className="c30 c10">
            <span className="c3">
              duplicate and release the song(s) as part of a video production in
              whatever format(s) necessary
            </span>
          </li>
          <li className="c30 c10">
            <span className="c3">
              use the music as a soundtrack &quot;synced&quot; with visual
              images as part of a production; and
            </span>
          </li>
          <li className="c10 c30">
            <span className="c3">
              right to use the music as part of the public viewing or broadcast
              of a production where the music is part of the production as
              defined by the previous two sub-points (a-b). You does not have
              the right to publicly perform WORKS on their own, separate and
              distinct from a production.
            </span>
          </li>
        </ol>
        <p className="c9">
          <span className="c0">
            RIGHTS NOT INCLUDED. You, the Licensee are NOT allowed under any of
            the terms of this site or Service to :
          </span>
        </p>
        <ol className="c34 lst-kix_swtwo7yy7guw-1">
          <li className="c9 c10">
            <span className="c0">
              &nbsp;exercise any of the rights to the licensed songs after the
              expiration of the licensing term, except for songs that have
              become part of other works or productions during the licensing
              terms,
            </span>
          </li>
          <li className="c9 c10">
            <span className="c0">
              claim authorship of the music represented under this agreement
              &nbsp;or of any derivative musical work created from a song;
            </span>
          </li>
          <li className="c9 c10">
            <span className="c0">
              &nbsp;transfer, share or sub-lease this license agreement to any
              other party without written authorization
            </span>
          </li>
          <li className="c9 c10">
            <span className="c0">retitle any of the songs</span>
          </li>
          <li className="c9 c10">
            <span className="c0">
              copy or duplicate the works in any way except for use in your
              productions
            </span>
          </li>
          <li className="c9 c10" id="h.30j0zll">
            <span className="c0">
              &nbsp;permit any other individual or third party the use of
              songs(s) by sharing, file-sharing, uploading or any other similar
              data-sharing process,
            </span>
          </li>
          <li className="c9 c10">
            <span className="c0">
              &nbsp;resell, trade, or exploit for profit the song(s) outright or
              as part of other music and/or audio-related collections, in part
              or in whole, to any other individual or party (although the music
              can be sold as part of a video production, which is a clear and
              distinct product from a song itself.) You cannot set up your own
              stock music library with files from PPMC.
            </span>
          </li>
          <li className="c9 c10">
            <span className="c0">
              Make the recording available in any manner that would enable a
              third party to download the song as a separate file,
            </span>
          </li>
          <li className="c9 c10">
            <span className="c0">
              Claim exclusive performance right or license. PPMC maintains the
              right to perform the song, or to transmit the work, to the public
              in any manner. You only have the public performance right for your
              productions that may contain songs from the PPMC Library.
            </span>
          </li>
          <li className="c10 c39">
            <span className="c0">
              &nbsp;use music in a pornographic production or in content that
              contains extremist or hate speech material.
            </span>
          </li>
        </ol>
        <p className="c18 c23">
          <span className="c3"></span>
        </p>
        <p className="c31">
          <span className="c3">
            Any of the licenses to music purchased on this Site are personal to
            you. You may not assign or transfer any of your rights or
            obligations under this Section or these Terms to any person or
            entity and any attempt to do so may void your license and prohibit
            you from using the music in any way.
          </span>
        </p>
        <p className="c31">
          <span className="c19 c37">
            Lowdown: Please don&rsquo;t steal any of our content and try to sell
            it as your own. This music &nbsp;took time and money to produce and
            we can only continue providing value like this if people are honest
            in agreeing to purchase terms. If you buy a song, only you can use
            it as we have described here and in the licensing agreement. You
            can&rsquo;t &ldquo;share&rdquo; a login or materials with someone
            else, unless you have a business account and the user is part of
            your organization. We can end your ability to access music if you
            violate these terms. You can&rsquo;t copy or scrape any of these
            materials onto your website, database, or social media channel --
            whether you are selling them or not. Thanks for investing in YOU -
            now allow us to keep on doing that for others. If you obtained music
            from anywhere other than PLEASANTPICTURES.CLUB, you have pirated
            content and we would appreciate you letting us know so we can take
            steps to address it.
          </span>
        </p>
        <p className="c31">
          <span className="c1">PROHIBITED CONDUCT </span>
        </p>
        <p className="c31">
          <span className="c3">
            You may use the Service only in accordance with all applicable laws
            and regulations. In addition, you agree not to: (i) transmit,
            install, upload, or transfer any virus, malware, Trojan horse, worm,
            robot, spider, scraper, web-crawler or other process that interferes
            with, disrupts, or damages the Service or any other system, hardware
            or software; (ii) provide others access to the Service using your
            username and password, or the username and password of another
            authorized user of the Service; (iii) copy, download, save, print,
            distribute, perform, display, modify, reverse engineer, disassemble,
            redistribute, republish, alter, create derivative works of, transfer
            or adapt any of the software, information, text, graphics, source
            code or HTML code, information or other content on the Service
            except as expressly allowed by these Terms; (iv) remove or modify
            any copyright, trademark, legal notices, or other proprietary
            information from content on the Service; (v) &ldquo;deep link&rdquo;
            to, &quot;frame,&quot; &quot;mirror,&quot; &quot;in-line link&quot;
            to, transfer to another person, or employ similar navigational
            technology to, the Service content; (vi) violate or attempt to
            violate our security mechanisms or otherwise breach or corrupt the
            Service&rsquo;s security in any way; (vii) violate a third
            party&#39;s intellectual property, personality, publicity (including
            exploiting minors) or other proprietary rights when using the
            Service; (viii) misrepresent your identity or personal information
            or impersonate another person or entity, including forging any
            TCP/IP packet so that any email appears to be generated by us; (ix)
            post obscene, harassing, defamatory, libelous, violent,
            pornographic, intimidating, abusive, threatening, offensive or
            illegal material on the Service; (x) advertise or otherwise solicit
            funds, goods or services on or through the Service or provide any
            commercial hosting service with access to the Service; or otherwise
            create or send &ldquo;spam&rdquo; or other unsolicited bulk email to
            any person or entity through the Service; (xi) engage in conduct
            that violates the laws of the United States or any foreign
            jurisdiction; or (xii) take any action that we determine in our sole
            discretion is in violation of these Terms or that in any way
            interferes, or attempts to interfere with, the Service (including
            another&rsquo;s ability to access and use the Service) or otherwise
            places an undue burden on the Service
          </span>
        </p>
        <p className="c29">
          <span className="c3">
            Lowdown: Don&rsquo;t share your login information, post porn or
            other obscene stuff, embed sketchy links, or try to hack us, tread
            on others&rsquo; property rights, or generally be a jerk. Please use
            your best judgment.
          </span>
        </p>
        <p className="c7">
          <span className="c1">THIRD PARTY CONTENT</span>
        </p>
        <p className="c7">
          <span className="c3">
            The Service may include social functions that allow users and other
            third parties to upload content onto the Service. The Service may
            also contain links and references to other third party services,
            websites, applications, and materials. We have no ownership or
            control over such third party content. We make no representations or
            warranties regarding, and do not sponsor or endorse, any third party
            content available on the Service. You understand and agree that we
            have no liability for such content and that, if you access or use
            third-party content through the Service, you do so at your own risk
            and may need to agree to third party licenses or agreements
          </span>
        </p>
        <p className="c7">
          <span className="c19 c27 c28">
            LOWDOWN: Third party links may take you off of
            PLEASANTPICTURES.CLUB.com. Most of those links lead to something
            awesome, but we are not responsible if they don&rsquo;t. If you
            share information with those third party sites or services, we may
            also get access to that information.
          </span>
        </p>
        <p className="c7">
          <span className="c1">PRODUCT DESCRIPTION.</span>
        </p>
        <p className="c7">
          <span className="c3">
            We try to describe our products and songs as accurately as possible,
            however the Site or Service may not be entirely accurate, current,
            or error-free. We may correct errors in pricing and descriptions and
            reserve the right to refuse or cancel any order with an incorrect
            price listing. You agree to be financially responsible for all
            purchases made by you or someone acting on your behalf through the
            Site. You agree to use the Site and to purchase services or products
            through the Site for legitimate purposes that comply with our
            licensing terms.
          </span>
        </p>
        <p className="c7">
          <span className="c1">ORDER CONFIRMATION</span>
        </p>
        <p className="c7">
          <span className="c3">
            We will email you to confirm the placement of your order and with
            details concerning your order or subscription. In the event that
            there is an error in this email confirmation, it is your
            responsibility to inform us as soon as possible. Please. We would
            never, ever, want you left hanging.
          </span>
        </p>
        <p className="c7">
          <span className="c3">
            If you&rsquo;ve signed up for a year subscription with us, the
            subscription renews automatically and your credit card will be
            charged the fees stated at the time of purchase (or the introductory
            rate during introductory period). Price may change at the end of
            your subscription period, but we will inform you of that ahead of
            time. Your subscription will start as soon as your credit card is
            successfully charged.
          </span>
        </p>
        <p className="c7">
          <span className="c1">CANCELLATIONS, REFUNDS &amp; RETURNS.</span>
        </p>
        <p className="c7">
          <span className="c19 c28">
            All sales of digital products downloadable upon confirmation of
            purchase are final and non-refundable. If you&rsquo;d like to cancel
            a yearly subscription you have with us, you can do so at the end of
            your year term and you will not be charged for the next year. Any
            merchandise or physical product is eligible for returns or exchanges
            within 30 days or purchase with a valid receipt. Please contact{' '}
          </span>
          <span className="c33 c19">
            <a className="c11" href="mailto:eric@pleasantpicturesmusicclub.com">
              e
            </a>
          </span>
          <span className="c33 c19 c32">
            <a className="c11" href="mailto:eric@pleasantpicturesmusicclub.com">
              ric@pleasantpicturesmusicclub.com
            </a>
          </span>
          <span className="c19 c32 c36">&nbsp;</span>
          <span className="c19 c28">
            for more information or use the Contact form on the website.
          </span>
        </p>
        <p className="c7">
          <span className="c1">RESERVATION OF RIGHTS</span>
        </p>
        <p className="c7">
          <span className="c3">
            &nbsp;We reserve the right, but do not have the obligation, to
            monitor the content and transactions on or through the Service. If
            we become aware of any content or transactions that we deem, in our
            sole discretion, to be in violation of these Terms or otherwise
            inappropriate, we may remove the content, cancel the transaction, or
            suspend, block, terminate or restrict access to the Service, without
            liability to you or any third party. We reserve the right to modify,
            suspend or discontinue the Service at any time, for any reason or no
            reason whatsoever, without any liability to you or any third party.
          </span>
        </p>
        <p className="c7">
          <span className="c3">
            You shall not post or transmit through the Site any material which
            violates or infringes the rights of others, or which is threatening,
            abusive, defamatory, libelous, invasive of privacy or publicity
            rights, vulgar, obscene, profane or otherwise objectionable,
            contains injurious formulas, recipes, or instructions, which
            encourages conduct that would constitute a criminal offense, give
            rise to civil liability or otherwise violate any law.
          </span>
        </p>
        <p className="c7">
          <span className="c19 c27 c28">
            LOWDOWN: Think before you post. If you would never say it to
            someone&rsquo;s face -- you probably shouldn&rsquo;t be saying it
            here. If you violate our rules, we can restrict your access.
          </span>
        </p>
        <p className="c7">
          <span className="c1">REFUSAL OF SERVICE.</span>
        </p>
        <p className="c7">
          <span className="c3">
            PLEASANT PICTURES LIBRARY LLC reserves the right to refuse service
            to any order, person, or entity without obligation to assign reason
            for doing so.
          </span>
        </p>
        <p className="c7">
          <span className="c1">MATERIAL YOU SUBMIT TO THE SITE.</span>
        </p>
        <p className="c7">
          <span className="c3">
            You shall not upload, post or otherwise make available on the Site
            any artwork, photos or other materials (collectively
            &ldquo;Materials&rdquo;) protected by copyright, trademark or other
            proprietary right without the express written permission of the
            owner of the copyright, trademark or other proprietary right. The
            burden of determining that any Materials are not so protected rests
            entirely with you. You shall be liable for any damage resulting from
            any infringement of copyrights, trademarks, or other proprietary
            rights, or any other harm resulting from such a submission and shall
            indemnify PLEASANT PICTURES LIBRARY LLC from any claim against PPMC
            resulting from your posting of Materials to the site. For all
            Materials submitted by you to the Site, you automatically represent
            or warrant that you have the authority to use and distribute the
            Materials, and that the use or display of the Materials will not
            violate any laws, rules, regulations or rights of third parties.
          </span>
        </p>
        <p className="c7">
          <span className="c3">
            PLEASANT PICTURES LIBRARY LLC reserves the right to remove from the
            site any Materials submitted by you that it deems inappropriate for
            the site or that appears to violate these terms and conditions.
          </span>
        </p>
        <p className="c7">
          <span className="c19 c27 c28">
            LOWDOWN: Don&rsquo;t post anything belonging to someone else.
          </span>
        </p>
        <p className="c7">
          <span className="c1">
            INTELLECTUAL PROPERTY RIGHTS TO YOUR MATERIALS.
          </span>
        </p>
        <p className="c7">
          <span className="c3">
            PLEASANT PICTURES LIBRARY LLC does not claim ownership of Material
            you may supply to PPMC. However, the act of posting Material to the
            site conveys an irrevocable, worldwide license to PPMC to use and
            distribute the posted Material in connection with PPMC&rsquo;s
            website and any related PPMC publications. You retain copyright
            ownership and any other rights you may rightfully hold in any
            content that you submit through the Service. By submitting Material
            to PPMC, you agree to hold PPMC harmless from and against all
            claims, liabilities and expenses arising out of any potential or
            actual copyright or trademark misappropriation or infringement
            claimed against you.
          </span>
        </p>
        <p className="c7">
          <span className="c19 c37">LOWDOWN</span>
          <span className="c19 c27 c28">
            : If you post something on our website, then we can use it on this
            site or our connected media, including social media or printed
            materials. We won&rsquo;t claim its ours -- but once you have posted
            the cat is out of the bag, so to speak.
          </span>
        </p>
        <p className="c7">
          <span className="c5 c28 c38">INTELLECTUAL PROPERTY INFRINGEMENT</span>
        </p>
        <p className="c7">
          <span className="c21 c5">
            Please do not submit confidential or proprietary information to us
            unless we have mutually agreed in writing otherwise.
          </span>
        </p>
        <p className="c7">
          <span className="c21 c5">
            We respect the intellectual property of others, and we ask you to do
            the same. If you or any user of this site believes their copyright,
            trademark or other property rights have been infringed by a posting
            on this site, you or the user should send notification to our
            Designated Agent (as identified below) immediately. To be effective,
            the notification must include:
          </span>
        </p>
        <ol className="c34 lst-kix_d96c1ldc7am8-0 start">
          <li className="c8">
            <span className="c21 c5">
              &nbsp;Identify in sufficient detail the copyrighted work that you
              believe has been infringed upon or other information sufficient to
              specify the copyrighted work being infringed.
            </span>
          </li>
          <li className="c8">
            <span className="c21 c5">
              Identify the material that you claim is infringing the copyrighted
              work listed in item #1 above.
            </span>
          </li>
          <li className="c8">
            <span className="c21 c5">
              Provide information reasonably sufficient to permit us to contact
              you (email address is preferred).
            </span>
          </li>
          <li className="c8">
            <span className="c21 c5">
              Provide information, if possible, sufficient to permit us to
              notify the owner/administrator of the allegedly infringing webpage
              or other content (email address is preferred).
            </span>
          </li>
          <li className="c8">
            <span className="c21 c5">
              Include the following statement: &ldquo;I have a good faith belief
              that use of the copyrighted materials described above as allegedly
              infringing is not authorized by the copyright owner, its agent, or
              the law.&rdquo;
            </span>
          </li>
          <li className="c8">
            <span className="c21 c5">
              Include the following statement: &ldquo;I swear, under penalty of
              perjury, that the information in the notification is accurate and
              that I am the copyright owner or am authorized to act on behalf of
              the owner of an exclusive right that is allegedly
              infringed.&rdquo;
            </span>
          </li>
          <li className="c8">
            <span className="c21 c5">Sign the paper.</span>
          </li>
          <li className="c8">
            <span className="c5 c21">
              Send the written communication to the following address: PLEASANT
              PICTURES LIBRARY LLC, 541 Aaron Ave Springville, UT 84663.
            </span>
          </li>
        </ol>
        <p className="c13">
          <span className="c3"></span>
        </p>
        <p className="c29">
          <span className="c19 c27 c28">
            LOWDOWN: If you think we have infringed upon someone else, please
            follow the steps above to contact us and let us know. We take this
            stuff seriously but we would need all of this information to do
            something about it. &nbsp;
          </span>
        </p>
        <p className="c29">
          <span className="c1">LIMITATION OF LIABILITY.</span>
        </p>
        <p className="c29">
          <span className="c3">
            You agree that under no circumstances shall we be liable for direct,
            indirect, incidental, consequential, special, punitive, exemplary,
            or any other damages arising out of your use of the Site or Service.
            Additionally, PPMC is not liable for damages in connection with (i)
            any failure of performance, error, omission, denial of service,
            attack, interruption, deletion, defect, delay in operation or
            transmission, computer virus or line or system failure; (ii) loss of
            revenue, anticipated profits, business, savings, goodwill or data;
            and (iii) third party theft of, destruction of, unauthorized access
            to, alteration of, or use of your information or property,
            regardless of our negligence, gross negligence, failure of an
            essential purpose and whether such liability arises in negligence,
            contract, tort, or any other theory of legal liability.
          </span>
        </p>
        <p className="c29">
          <span className="c3">
            The foregoing applies even if PPMC has been advised of the
            possibility of or could have foreseen the damages. In those states
            that do not allow the exclusion or limitation of liability for the
            damages, our liability is limited to the fullest possible extent
            permitted by law. In no event shall PPMC&rsquo;s cumulative
            liability to you exceed the total purchase price of the Service you
            have purchased from PPMC, and if no purchase has been made by you
            PPMC&rsquo;s cumulative liability to you shall not exceed $100.
          </span>
        </p>
        <p className="c29">
          <span className="c19 c27 c28">
            LOWDOWN: We work hard to provide you with an awesome service, but
            we&rsquo;re not liable for these various crazy and unlikely
            contigencies.
          </span>
        </p>
        <p className="c29">
          <span className="c1">DISPUTE RESOLUTION</span>
        </p>
        <p className="c29">
          <span className="c19">DISPUTE RESOLUTION WILL ONLY BE BY </span>
          <span className="c19 c41">BINDING</span>
          <span className="c3">
            &nbsp;ARBITRATION. PLEASE read this section carefully.
          </span>
        </p>
        <p className="c29">
          <span className="c3">
            Except for any disputes relating to intellectual property rights,
            obligations, or any infringement claims, any disputes with PPMC
            arising out of or relating to the Agreement shall be governed by
            Utah law regardless of your country of origin or where you access
            PleasantPictures.club, and notwithstanding of any conflicts of law
            principles and the United Nations Convention for the International
            Sale of Goods. Any Disputes shall be resolved by final and binding
            arbitration under the rules and auspices of the American Arbitration
            Association, to be held in Salt Lake City, Utah in English, with a
            written decision stating legal reasoning issued by the arbitrator(s)
            at either party&#39;s request, and with arbitration costs and
            reasonable documented attorneys&#39; costs of both parties to be
            borne by the party that ultimately loses. Either party may obtain
            injunctive relief (preliminary or permanent) and orders to compel
            arbitration or enforce arbitral awards in any court of competent
            jurisdiction.&rdquo;
          </span>
        </p>
        <p className="c29">
          <span className="c3">
            There is no judge or jury in arbitration, and court review of an
            arbitration award is limited. However, an arbitrator can award on an
            individual basis the same damages and relief as a court (including
            injunctive and declaratory relief or statutory damages), and must
            follow the terms of these Conditions of Use as a court would.
          </span>
        </p>
        <p className="c12">
          <span className="c3">
            To begin an arbitration proceeding, you must send a letter
            requesting arbitration and describing your claim to our registered
            agent at:
          </span>
        </p>
        <p className="c22">
          <span className="c3">PLEASANT PICTURES LIBRARY LLC</span>
        </p>
        <p className="c22">
          <span className="c3">541 Aaron Ave</span>
        </p>
        <p className="c22">
          <span className="c3">Springville, UT 84663 </span>
        </p>
        <p className="c12 c23">
          <span className="c3"></span>
        </p>
        <p className="c12">
          <span className="c3">
            The arbitration will be conducted by the American Arbitration
            Association (AAA) under its rules, including the AAA&#39;s
            Supplementary Procedures for Consumer-Related Disputes. The
            AAA&#39;s rules are available at www.adr.org or by calling
            1-800-778-7879. Payment of all filing, administration and arbitrator
            fees will be governed by the AAA&#39;s rules. We will reimburse
            those fees for claims totaling less than $10,000 unless the
            arbitrator determines the claims are frivolous. Likewise, PPMC
            &nbsp;will not seek attorneys&#39; fees and costs in arbitration
            unless the arbitrator determines the claims are frivolous.
          </span>
        </p>
        <p className="c12">
          <span className="c3">
            We each agree that any dispute resolution proceedings will be
            conducted only on an individual basis and not in a class,
            consolidated or representative action. If for any reason a claim
            proceeds in court rather than in arbitration we each waive any right
            to a jury trial. We also both agree that you or we may bring suit in
            court to enjoin infringement or other misuse of intellectual
            property rights.
          </span>
        </p>
        <p className="c29">
          <span className="c19 c27 c28">
            LOWDOWN: We&rsquo;re not responsible for your use of the site. If
            you think we are and we have a problem we cannot resolve, then
            let&rsquo;s work it out within one year via arbitration style under
            Utah State law.
          </span>
        </p>
        <p className="c29">
          <span className="c1">DISCLAIMER</span>
        </p>
        <p className="c25 c22">
          <span className="c4">You expressly acknowledge and agree that: </span>
        </p>
        <p className="c16">
          <span className="c4 c5">
            (1) your use of the services are at your sole risk. the services are
            provided on an &quot;as is&quot; and &quot;as available&quot; basis.
            ppmc expressly disclaims all warranties of any kind, whether express
            or implied, including, but not limited to, the implied warranties of
            merchantability, fitness for a particular purpose, and
            non-infringement.
          </span>
        </p>
        <p className="c16">
          <span className="c4 c5">(2) PPMC &nbsp;makes no warranty that </span>
        </p>
        <p className="c6">
          <span className="c4 c5">
            (a) the services will meet your requirements;
          </span>
        </p>
        <p className="c6">
          <span className="c4 c5">
            &nbsp;(b) the services will be uninterrupted, timely, unfailingly
            secure, or error-free;
          </span>
        </p>
        <p className="c6">
          <span className="c4 c5">
            (c) the results that may be obtained from the use of the services
            will be accurate or reliable;
          </span>
        </p>
        <p className="c6">
          <span className="c4 c5">
            (d) the quality of any products, services, information, or other
            material purchased or obtained by you through the services will meet
            your expectations and (e) any errors in the software will be
            corrected.
          </span>
        </p>
        <p className="c16">
          <span className="c4 c5">
            (3) any material downloaded or otherwise obtained through the use of
            the services is done at your own discretion and risk and that you
            will be solely responsible for any damage to your computer system or
            loss of data that results from the download of any such material.
          </span>
        </p>
        <p className="c16">
          <span className="c4 c5">
            (4) no advice or information, whether oral or written, obtained by
            you from ppmc or through or from the services shall create any
            warranty not expressly stated in the terms and conditions
          </span>
        </p>
        <p className="c16">
          <span className="c4 c5">
            &nbsp;(5) you should always use caution when giving out any
            personally identifying information about yourself or those for whom
            you have legal authority. ppmc does not control or endorse any
            actions resulting from your participation in the services and,
            therefore, ppmc specifically disclaims any liability with regard to
            any actions resulting from your participation in the services.
          </span>
        </p>
        <p className="c25 c22">
          <span className="c4 c27">
            LOWDOWN: The site doesn&rsquo;t claim to be anything more than what
            it is and we are not liable for damages for these unlikely events
            that could somehow be connected to you using our site or service.
          </span>
        </p>
        <p className="c20">
          <span className="c2">LIMITATION OF LIABILITY</span>
        </p>
        <p className="c22 c25">
          <span className="c4 c5">
            Within the limits allowed by applicable laws, you expressly
            acknowledge and agree that ppmc shall not be liable for any direct,
            indirect, incidental, special, consequential, or exemplary damages,
            including but not limited to, damages for loss of profits, goodwill,
            use, data or other intangible losses (even if PPMC has been advised
            of the possibility of such damages), resulting from:
          </span>
        </p>
        <p className="c16">
          <span className="c4 c5">
            &nbsp;(a) the use or the inability to use the services;
          </span>
        </p>
        <p className="c16">
          <span className="c4 c5">
            (b) any action you take based on the information you receive in
            through or from the services,
          </span>
        </p>
        <p className="c16">
          <span className="c4 c5">
            (c) your failure to keep your password or account details secure and
            confidential,
          </span>
        </p>
        <p className="c16">
          <span className="c4 c5">
            (d) the cost of procurement of substitute goods and services
            resulting from any goods, data, information, or services purchased
            or obtained or messages received or transactions entered into
            through or from the services;
          </span>
        </p>
        <p className="c16">
          <span className="c4 c5">
            &nbsp;(e) unauthorized access to or alteration of your transmissions
            or data;
          </span>
        </p>
        <p className="c16">
          <span className="c4 c5">
            (f) the improper authorization for the services by someone claiming
            such authority; or
          </span>
        </p>
        <p className="c16">
          <span className="c4">
            (g) statements or conduct of any third party on the services.
          </span>
        </p>
        <p className="c25 c22">
          <span className="c4 c27">
            Lowdown: The site doesn&rsquo;t claim to be anything more than what
            it is and we are not liable for damages for these unlikely events
            that could somehow be connected to you using our site or service.
          </span>
        </p>
        <p className="c20">
          <span className="c1">MISCELLANEOUS</span>
        </p>
        <ol className="c34 lst-kix_ypfljtwxagje-0 start">
          <li className="c20 c24">
            <span className="c2 c14">Entire Agreement.</span>
            <span className="c4 c5">
              &nbsp;The Terms and Conditions &nbsp;constitute the entire
              agreement between you and PPMC and govern your use of the
              Services, superseding any prior agreements between you and PPMC on
              this subject. You also may be subject to additional terms and
              conditions that may apply when you use affiliate services,
              third-party content, or third-party software
            </span>
          </li>
          <li className="c20 c24">
            <span className="c2 c35">Changes to the Terms of Service.</span>
            <span className="c19 c35">&nbsp;</span>
            <span className="c4 c5">
              PPMC may make changes to the Terms and Conditions &nbsp;from time
              to time. When these changes are made, PPMC will make a new copy of
              the Terms and Conditions &nbsp;available on its website and any
              new additional terms will be made available to you from within, or
              through, the affected Services. You acknowledge and agree that if
              you use the Services after the date on which the Terms and
              Conditions &nbsp;have changed, PPMC will treat your use as
              acceptance of the updated Terms and Conditions .
            </span>
          </li>
          <li className="c20 c24">
            <span className="c2 c14">Waiver.</span>
            <span className="c4 c5">
              &nbsp;The failure of PPMC to exercise or enforce any right or
              provision of the Terms and Conditions &nbsp;shall not constitute a
              waiver of such right or provision. If any provision of the Terms
              and Conditions &nbsp;is found by a court of competent jurisdiction
              to be invalid, the parties nevertheless agree that the court
              should endeavor to give effect to the parties&#39; intentions as
              reflected in the provision, and the other provisions of the Terms
              and Conditions &nbsp;remain in full force and effect.
            </span>
          </li>
          <li className="c20 c24">
            <span className="c2 c14">Term for cause of action.</span>
            <span className="c4 c5">
              &nbsp;You agree that regardless of any statute or law to the
              contrary, any claim or cause of action arising out of or related
              to use of the Services or the Terms and Conditions &nbsp;must be
              filed within one (1) year after such claim or cause of action
              arose or be forever barred.
            </span>
          </li>
          <li className="c20 c24">
            <span className="c2 c14">Admissibility of printed version.</span>
            <span className="c4 c5">
              &nbsp;A printed version of this agreement and of any notice given
              in electronic form shall be admissible in judicial or
              administrative proceedings based upon or relating to this
              agreement to the same extent and subject to the same conditions as
              other business documents and records originally generated and
              maintained in printed form.
            </span>
          </li>
          <li className="c20 c24">
            <span className="c2 c14">Section titles.</span>
            <span className="c4 c5">
              &nbsp;The section titles in the Terms and Conditions &nbsp;are for
              convenience only and have no legal or contractual effect.
            </span>
          </li>
          <li className="c20 c24">
            <span className="c2 c14">Severability Clause.</span>
            <span className="c4 c5">
              &nbsp;If any portion of these Terms and Conditions &nbsp;is found
              to be unenforceable, the remaining portion will remain in full
              force and effect.
            </span>
          </li>
          <li className="c20 c24">
            <span className="c2 c14">Amendments.</span>
            <span className="c4">
              &nbsp;We reserve the right to modify, supplement or replace the
              terms of the Agreement, effective upon posting at{' '}
            </span>
            <span className="c33">
              <a
                className="c11"
                href="https://www.google.com/url?q=https://www.pleasantpictures.club&amp;sa=D&amp;ust=1568076644865000"
              >
                www.pleasantpictures.club
              </a>
            </span>
            <span>&nbsp; </span>
            <span className="c4 c5">
              or notifying you otherwise. If you do not want to agree to changes
              to the Agreement, you can terminate the Agreement at any time.
            </span>
          </li>
          <li className="c20 c24">
            <span className="c2 c14">Assignment:</span>
            <span className="c4 c5">
              &nbsp;You may not assign or delegate any rights or obligations
              under the Agreement. Any purported assignment and delegation shall
              be ineffective. We may freely assign or delegate all rights and
              obligations under the Agreement, fully or partially without notice
              to you. We may also substitute, by way of unilateral novation,
              effective upon notice to you, PPMC for any third party that
              assumes our rights and obligations under this Agreement.
            </span>
          </li>
        </ol>
        <p className="c13">
          <span className="c3"></span>
        </p>
        <p className="c18">
          <span className="c1">COPYRIGHT NOTICE.</span>
        </p>
        <p className="c18">
          <span className="c3">
            Copyright &copy; 2017 by PLEASANT PICTURES LIBRARY LLC. All rights
            reserved.
          </span>
        </p>
        <p className="c22 c32">
          <span className="c19">Updated: January 1, 2018</span>
        </p>
        <p className="c22 c23">
          <span className="c3"></span>
        </p>
      </div>
    </div>
  );
};
