import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import { CustomTheme } from '../../types';
import { MusicPackPage } from './MusicPackPage';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {},
  })
);
export interface HighEndMusicPackProps {}

export const HighEndMusicPack = ({}: HighEndMusicPackProps) => {
  const classes = useStyles();

  return (
    <MusicPackPage
      image={
        <img src="https://s3-us-west-2.amazonaws.com/pleasantpictures-images/highend.jfif" />
      }
      collectionName={'highEndMusicCollectionID'}
    >
      Sometimes you need a rad pack of songs to give your edits the boost they
      need. Introducing our High End Music Bundle, 30 incredible tracks to
      instantly enhance your productions. Once you purchase this pack these
      tracks yours to use forever! Create confidently.
    </MusicPackPage>
  );
};
