import { Coupon } from '../api/coupons';
import { applyCouponToAmount } from '../utils/applyCouponToAmount';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { CreateUserBody } from '../api/users';
import { useCreateUserApiCall } from '../api/users/hooks';
import { persistToken } from '../utils/tokenUtils';
import { useSession } from '../providers/SessionProvider';
import { recordPurchase } from '../utils/affiliateProgram';

function useCheckout<
  T extends { token?: string; paymentMethodID?: string; coupon?: string | null }
>({
  coupon,
  baseAmount,
  runApiCall,
  signupFormData,
  onSuccess,
}: {
  coupon?: Coupon | null;
  baseAmount: number;
  signupFormData?: CreateUserBody | null;
  runApiCall: (args: T) => Promise<unknown>;
  onSuccess: (result: { totalPaid: number }) => void;
}): {
  completeCheckout: (
    checkoutParameters: Omit<T, 'token' | 'coupon' | 'paymentMethodID'>
  ) => Promise<void>;
  amountWithDiscount: number;
  error?: string;
  loading: boolean;
} {
  const [loading, setLoading] = useState<boolean>(false);
  const createUserResult = useCreateUserApiCall();
  const elements = useElements();
  const stripe = useStripe();
  const [error, setError] = useState<string>('');
  const session = useSession();

  const handleSubmit = async (
    variables: Omit<T, 'token' | 'coupon' | 'paymentMethodID'>
  ) => {
    try {
      const card = elements?.getElement(CardElement);
      setError('');
      if (!card || !stripe) {
        return;
      }
      setLoading(true);

      if (!session.user) {
        if (!signupFormData) {
          setError('Please login or create an account to complete this action');
          return;
        }
        const response = await createUserResult.runApiCall(
          signupFormData as CreateUserBody
        );
        if (response?.token) {
          persistToken(response.token);
        }
      }

      const paymentMethodResult = await stripe.createPaymentMethod({
        type: 'card',
        card,
      });
      if (paymentMethodResult.error) {
        setError(paymentMethodResult?.error.message || '');
        return;
      }

      const tokenResponse = await stripe.createToken(card);
      if (!tokenResponse.token) {
        setError('Error with generating checkout token, try again.');
      }

      await runApiCall({
        ...variables,
        paymentMethodID: paymentMethodResult.paymentMethod.id,
        token: tokenResponse.token?.id,
        coupon: coupon ? coupon.id : undefined,
      } as any);
      onSuccess({
        totalPaid: coupon
          ? applyCouponToAmount(baseAmount, coupon)
          : baseAmount,
      });
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    amountWithDiscount: coupon
      ? applyCouponToAmount(baseAmount, coupon)
      : baseAmount,
    error,
    loading,
    completeCheckout: handleSubmit,
  };
}

export const useCheckoutSignup = useCheckout;
