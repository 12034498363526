import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: { textAlign: 'left', padding: '1rem 3rem', fontSize: '1.25rem' },
  mainContent: { background: '#D8D8D8' },
}));
export default function Tracks() {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.title}>Tracks</div>
      <div className={classes.mainContent}>Tracks content</div>
    </div>
  );
}
