import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import { useRouteMatch } from 'react-router';
import { CustomTheme } from '../../types';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {},
  })
);
export interface PurchaseTermsProps {}

export const PurchaseTerms = ({}: PurchaseTermsProps) => {
  const routeParams = useRouteMatch<{ name: string }>();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>
        <p>
          <span>Single Track</span>
          <span>
            &nbsp;License Agreement &ndash; Pleasant Pictures Music Club
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span>
            You are granted a limited, worldwide, non-exclusive license to use
            the single song track you downloaded: &ldquo;
            {routeParams.params.name}&rdquo; &nbsp;in perpetuity in accordance
            with the terms listed here and on the Pleasant Pictures Music Club{' '}
          </span>
          <span>website</span>
          <span>
            &nbsp;at https://www.pleasantpictures.club/subscription-terms.
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span>You </span>
          <span>CAN</span>
          <span>:</span>
        </p>
        <ol>
          <li>
            <span>duplicate and release the </span>
            <span>song</span>
            <span>
              &nbsp;as part of a video production for personal or commercial use
            </span>
            <span>;</span>
          </li>
          <li>
            <span>use the </span>
            <span>song</span>
            <span>
              &nbsp;as a soundtrack &quot;synced&quot; with visual images as
              part of a production
            </span>
            <span>;</span>
          </li>
          <li>
            <span>&nbsp;</span>
            <span>use the song in a </span>
            <span>public viewing or broadcast of a production where the </span>
            <span>song</span>
            <span>&nbsp;is part of the </span>
            <span>production</span>
            <span>&nbsp;and is not the </span>
            <span>
              main attraction (the song can be played during a
              &nbsp;snowboarding highlight reel, but should not be part of a
              concert)
            </span>
            <span>.</span>
          </li>
        </ol>
        <p>
          <span></span>
        </p>
        <p>
          <span>You </span>
          <span>CANNOT</span>
          <span>: </span>
          <span>&nbsp;</span>
        </p>
        <ol>
          <li>
            <span>claim authorship of the song or of any derivative work,</span>
          </li>
          <li>
            <span>
              &nbsp;transfer, share, or sub-lease this license agreement to
              anyone else,
            </span>
          </li>
          <li>
            <span>retitle the song; </span>
          </li>
          <li>
            <span>
              resell/share/trade/make available for download the song or make
              the song available in any manner that would enable a third party
              access to song as a separate file;
            </span>
          </li>
          <li>
            <span>
              claim any exclusive right or license, including an exclusive
              public performance right to the song;
            </span>
          </li>
          <li>
            <span>s</span>
            <span>
              ell products where the main value of the product is the song
              itself, for example, you can&rsquo;t put one of our songs on a
              music album or host a concert where this song will be played. The
              song must be PART of something else, not the actual product itself
              that you are commercially exploiting.
            </span>
          </li>
          <li>
            <span>
              &nbsp;use the track in a pornographic production or in content
              that contains extremist or hate speech material.
            </span>
          </li>
        </ol>
        <p>
          <span>This license will be governed by the laws of Utah.</span>
        </p>
        <p>
          <span>
            You are required to credit the song to &ldquo;Pleasant Pictures
            Music Club&rdquo; where applicable,
          </span>
          <span>&nbsp;</span>
          <span>(</span>
          <span>
            in liner notes, rolling credits, verbal acknowledgment, etc.)
          </span>
        </p>
        <p>
          <span>
            In witness thereof, the parties have executed this Agreement as of
            the date written above.
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span>
            THIS CONSTITUTES THE ENTIRE AGREEMENT BETWEEN THE PARTIES.
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span></span>
        </p>
        <a id="t.56d82577374f2113cbc85eb5616ea29674d2ed2b"></a>
        <a id="t.0"></a>
        <table>
          <tbody>
            <tr>
              <td>
                <p>
                  <span>LICENSOR &nbsp;- </span>
                </p>
                <p>
                  <span>By: Pleasant Picture Library LLC </span>
                </p>
                <p>
                  <span>Name:</span>
                </p>
                <p>
                  <span>Title:</span>
                </p>
                <p>
                  <span>DATE:</span>
                </p>
                <p>
                  <span></span>
                </p>
              </td>
              <td>
                <p>
                  <span>LICENSEE </span>
                </p>
                <p>
                  <span>By:_________________________________</span>
                </p>
                <p>
                  <span>Name:</span>
                </p>
                <p>
                  <span>Title:</span>
                </p>
                <p>
                  <span>DATE:</span>
                </p>
                <p>
                  <span></span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          <span></span>
        </p>
        <p>
          <span></span>
        </p>
        <div>
          <p>
            <span></span>
          </p>
        </div>
      </div>
    </div>
  );
};
