import { Link, Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import { useHistory } from 'react-router';
import { useSession } from '../../providers/SessionProvider';
import { CustomTheme } from '../../types';
import AdminNav from './AdminNav';
import Categories from './AdminCategories/categories/Categories';
import Coupons from './AdminCategories/coupons/Coupons';
import PackCodes from './AdminCategories/packCodes/PackCodes';
import Plans from './AdminCategories/plans/Plans';
import Reports from './AdminCategories/reports/Reports';
import SiteConfig from './AdminCategories/siteConfig/SiteConfig';
import Sync from './AdminCategories/sync/Sync';
import Tags from './AdminCategories/tags/Tags';
import Tracks from './AdminCategories/tracks/Tracks';
import Users from './AdminCategories/users/Users';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {
      display: 'flex',
      textAlign: 'center',
    },
    navigation: {
      textAlign: 'center',
    },
    content: {
      width: '100%',
    },
  })
);

const Admin = () => {
  const classes = useStyles();
  const session = useSession();
  const history = useHistory();

  return (
    <div className={classes.container}>
      <div className={classes.navigation}>
        <AdminNav />
      </div>
      <div className={classes.content}>
        <Route exact path="/admin/users/" component={Users} />
        <Route exact path="/admin/categories/" component={Categories} />
        <Route exact path="/admin/coupons/" component={Coupons} />
        <Route exact path="/admin/pack-codes/" component={PackCodes} />
        <Route exact path="/admin/plans/" component={Plans} />
        <Route exact path="/admin/reports/" component={Reports} />
        <Route exact path="/admin/site-config/" component={SiteConfig} />
        <Route exact path="/admin/sync/" component={Sync} />
        <Route exact path="/admin/tags/" component={Tags} />
        <Route exact path="/admin/tracks/" component={Tracks} />
      </div>
    </div>
  );
};

export default Admin;
