import { Button, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Coupon } from '../../api/coupons';
import { useGetCouponApiCall } from '../../api/coupons/hooks';
import { getCouponEffectString } from '../../utils/getCouponEffectString';

export const CouponInput = ({
  onCoupon,
}: {
  onCoupon: (coupon: Coupon | null) => void;
}) => {
  const [code, setCode] = useState<string>('');
  const { loading, data, error } = useGetCouponApiCall(
    { code },
    { skip: Boolean(code) === false }
  );

  useEffect(() => {
    if (error) {
      onCoupon(null);
    }
    if (data) {
      onCoupon(data);
    }
  }, [data, error]);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setCode(e.target.value);
    if (e.target.value === '') {
      onCoupon(null);
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setCode((e.target as any).value);
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <TextField
        onBlur={handleBlur}
        name="coupon"
        label="Coupon Code"
        error={Boolean(error)}
        onKeyDown={handleKeyDown}
        helperText={
          error ? error : data && code ? getCouponEffectString(data) : ''
        }
        fullWidth
      />
      <div>
        <Button
          disabled={loading}
          size="small"
          color="primary"
          variant="contained"
        >
          Apply
        </Button>
      </div>
    </div>
  );
};
