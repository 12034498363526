import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Button,
  Typography,
  Checkbox,
  TextField,
  MenuItem,
  Table,
  TableCell,
  TableRow,
  TableBody,
  CircularProgress,
  TableContainer,
  Paper,
  Divider,
} from '@material-ui/core';

import {
  useGetSiteConfigsApiCall,
  useUpdateSiteConfigApiCall,
} from '../../../../api/siteConfig/hooks';
import { useGetCategoriesApiCall } from '../../../../api/categories/hooks';
import { GetCategoriesResponse } from '../../../../api/categories';
import { GetSiteConfigsResponse, SiteConfig } from '../../../../api/siteConfig';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      textAlign: 'left',
      padding: theme.spacing(1),
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(6),
    },
    mainContent: { background: theme.palette.background.default },
    button: {
      display: 'flex',
      margin: theme.spacing(1),
      marginLeft: theme.spacing(4),
      maxWidth: theme.spacing(30),
      maxHeight: theme.spacing(6),
    },
    table: {},
    tableHeader: { fontWeight: 'bold' },
    tableRow: { background: theme.palette.background.default },
    form: {
      textAlign: 'left',
    },
    input: {
      margin: theme.spacing(3, 0),
    },
    textInput: {
      marginRight: theme.spacing(14),
    },
  })
);
interface CreditCardCheckboxProps {
  siteConfigResult?: GetSiteConfigsResponse;
  onChange: (creditCardRequirement: boolean) => void;
}
function CreditCardCheckbox({
  siteConfigResult,
  onChange,
}: CreditCardCheckboxProps) {
  const [
    creditCardRequirementStatus,
    setCreditCardRequirementStatus,
  ] = useState<boolean | undefined>(
    siteConfigResult?.[0].info.noCreditCardRequired
  );
  const handleCreditCardRequirementChange = (e: any) => {
    setCreditCardRequirementStatus(e.target.checked);
    if (creditCardRequirementStatus != undefined) {
      onChange(e.target.checked);
    }
  };
  return (
    <>
      <Checkbox
        color="primary"
        checked={creditCardRequirementStatus}
        onChange={handleCreditCardRequirementChange}
      />
    </>
  );
}

interface CategorySelectorProps {
  categoryList?: GetCategoriesResponse;
  collectionName: string;
  siteConfigResult?: GetSiteConfigsResponse;
  onChange: (collectionName: string, categoryID: number) => void;
}
function CategorySelector({
  categoryList,
  collectionName,
  onChange,
  siteConfigResult,
}: CategorySelectorProps) {
  const [categoryID, setCategoryID] = useState<number | undefined>(
    (siteConfigResult?.[0].info as any)[collectionName]
  );
  const handleCollectionCategoryChange = (e: any) => {
    setCategoryID(e.target.value);
    if (categoryID) {
      onChange(collectionName, e.target.value);
    }
  };
  return (
    <>
      <TextField
        select
        value={
          categoryList?.find((category) => category._id == categoryID)?._id
        }
        onChange={handleCollectionCategoryChange}
      >
        {categoryList?.map((category) => (
          <MenuItem value={category._id}>{category.name}</MenuItem>
        ))}
      </TextField>
    </>
  );
}
export default function SiteConfiguration() {
  const classes = useStyles();
  const siteConfigResult = useGetSiteConfigsApiCall();
  const categoriesResult = useGetCategoriesApiCall();
  const updateResult = useUpdateSiteConfigApiCall();

  const handleCreditCardRequirement = async (
    creditCardRequirement: boolean
  ) => {
    await updateResult.runApiCall({
      ...siteConfigResult,
      info: {
        ...siteConfigResult?.data?.[0]?.info,
        noCreditCardRequired: creditCardRequirement,
      },
    });
    siteConfigResult.refetch();
  };

  const handleCollectionIDChange = async (
    collectionName: string,
    categoryID: number
  ) => {
    await updateResult.runApiCall({
      ...siteConfigResult,
      info: {
        ...siteConfigResult?.data?.[0]?.info,
        [collectionName]: categoryID,
      },
    });
    siteConfigResult.refetch();
  };

  return (
    <div className={classes.mainContent}>
      {!categoriesResult.data ||
      !siteConfigResult.data ||
      siteConfigResult.loading ? (
        <CircularProgress />
      ) : (
        <>
          <Typography variant="h4" className={classes.title}>
            Site Config
          </Typography>
          <Divider />
          <div className={classes.form}>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body2">
                        NO CREDIT CARD REQUIRED FOR PURCHASING TRACKS
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <CreditCardCheckbox
                        siteConfigResult={siteConfigResult.data}
                        onChange={handleCreditCardRequirement}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <TableCell>
                      <Typography variant="body2">
                        VIDEOGRAPHER PURCHASE COLLECTION
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <CategorySelector
                        categoryList={categoriesResult.data}
                        siteConfigResult={siteConfigResult.data}
                        collectionName={'videographerCollectionID'}
                        onChange={handleCollectionIDChange}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant="body2">
                        HIGH END MUSIC COLLECTION
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <CategorySelector
                        categoryList={categoriesResult.data}
                        siteConfigResult={siteConfigResult.data}
                        collectionName={'highEndMusicCollectionID'}
                        onChange={handleCollectionIDChange}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow className={classes.tableRow}>
                    <TableCell>
                      <Typography variant="body2">
                        STOCK MUSIC PRO BUNDLE
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <CategorySelector
                        categoryList={categoriesResult.data}
                        siteConfigResult={siteConfigResult.data}
                        collectionName={'stockMusicProBundleCollectionID'}
                        onChange={handleCollectionIDChange}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      )}
    </div>
  );
}
