import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  Grid,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { useCreateTagApiCall } from '../../../../api/tags/hooks';
import { CreateTagBody, META_TAG_CATEGORIES } from '../../../../api/tags';

interface Props {
  handleModalClose: VoidFunction;
  onCreateTag: VoidFunction;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: { flexWrap: 'wrap' },
    input: {
      margin: theme.spacing(3, 0),
      width: '15vw',
    },
    textInput: {
      marginLeft: theme.spacing(1),
    },
    errors: {
      marginTop: theme.spacing(-2),
      marginBottom: theme.spacing(2),
    },
  })
);

export default function NewTagForm(props: Props) {
  const classes = useStyles();
  const createResult = useCreateTagApiCall();
  const [tagName, setTagName] = useState<string>('');
  const [tagCategoryChange, setTagCategoryChange] = useState<string>('');
  const [errors, setErrors] = useState({
    tagNameError: '',
  });
  const handleTagName = (event: any) => {
    setTagName(event.target.value);
  };
  const handleTagCategoryChange = (event: any) => {
    setTagCategoryChange(event.target.value);
  };
  const handleCreateTag = (createTagBody: CreateTagBody) => {
    const isValid = validate(createTagBody.name);
    if (isValid) {
      createResult
        .runApiCall(createTagBody)
        .then(() => props.onCreateTag())
        .then(props.handleModalClose)
        .catch((error) => console.error(error));
    }
  };

  const validate = (tagName: string) => {
    const charMin = 1;
    const charMax = 50;
    let tagNameError = '';

    if (tagName.length < charMin || tagName.length > charMax) {
      tagNameError = 'Coupon name must be between 1 and 50 characters';
    }

    if (tagNameError) {
      setErrors({
        tagNameError,
      });
      return false;
    }
    return true;
  };

  return (
    <div className={classes.container}>
      <DialogTitle>Add a New Coupon</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container direction="column" alignItems="center" justify="center">
          <FormControl
            className={classes.input}
            error={errors.tagNameError ? true : false}
          >
            <InputLabel className={classes.textInput}>Tag Name</InputLabel>
            <OutlinedInput
              error={errors.tagNameError ? true : false}
              autoFocus
              required={true}
              id="tag"
              type="string"
              value={tagName}
              onChange={handleTagName}
            />
          </FormControl>
          <Typography
            variant="body1"
            color={'error'}
            className={classes.errors}
          >
            {errors.tagNameError}
          </Typography>

          <FormControl variant="outlined" className={classes.input}>
            <InputLabel>Category</InputLabel>
            <Select
              id="category"
              value={tagCategoryChange}
              onChange={handleTagCategoryChange}
              label={'Category'}
            >
              {META_TAG_CATEGORIES.map((category) => (
                <MenuItem value={category}>{category}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              handleCreateTag({
                name: tagName,
                category:
                  tagCategoryChange === '' ? 'No Category' : tagCategoryChange,
                active: true,
              })
            }
          >
            Submit
          </Button>
          <Typography color="error">{createResult.error}</Typography>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button color="primary" onClick={props.handleModalClose}>
          Cancel
        </Button>
      </DialogActions>
    </div>
  );
}
