import React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { CustomTheme } from '../types';
import clsx from 'clsx';
import { Button, Typography } from '@material-ui/core';

const VERTICAL_PADDING = 3;
const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    content: {
      paddingTop: theme.spacing(VERTICAL_PADDING),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(VERTICAL_PADDING, 4),
      },
      [theme.breakpoints.only('xs')]: {
        padding: theme.spacing(VERTICAL_PADDING, 1),
      },
      padding: theme.spacing(0, '10%'),
    },
    title: {
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        fontSize: 30,
        marginBottom: theme.spacing(1.5),
      },
    },
    header: {
      minHeight: '50vh',
      padding: theme.spacing(3),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
  })
);

interface Props {
  children: any;
  className?: string;
  title?: string;
  imgSrc: string;
  action: JSX.Element;
}

const HeaderImageLayout = ({
  children,
  className,
  title,
  imgSrc,
  action,
}: Props) => {
  const classes = useStyles();
  return (
    <div className={clsx(className)}>
      <div
        style={{ backgroundImage: `url(${imgSrc})` }}
        className={classes.header}
      >
        {title && (
          <Typography variant="h3" className={classes.title}>
            {title}
          </Typography>
        )}
        {action && action}
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default HeaderImageLayout;
