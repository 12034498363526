import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import { CustomTheme } from '../../types';
import { MusicPackPage } from './MusicPackPage';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {},
  })
);
export interface StockMusicPackProps {}

export const StockMusicPack = ({}: StockMusicPackProps) => {
  const classes = useStyles();

  return (
    <MusicPackPage
      image={
        <img src="https://pleasantpictures-images.s3-us-west-2.amazonaws.com/IMG_5305+(1).JPG" />
      }
      collectionName={'stockMusicProBundleCollectionID'}
      hideBuySection={true}
    >
      The right music makes all the difference. We've curated this pack of 25
      high end tracks for you to drop right into your projects and instantly
      enhance the vibe. Once you purchase this pack you own the rights to these
      tracks in perpetuity. Enjoy!
    </MusicPackPage>
  );
};
