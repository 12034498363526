import { IconButton, makeStyles, Slider, Typography } from '@material-ui/core';
import { VolumeMute, VolumeUp } from '@material-ui/icons';
import { createStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { useAudio } from '../../providers/AudioProvider';
import { CustomTheme } from '../../types';
import {
  NextButton,
  PauseButton,
  PlayButton,
  PreviousButton,
} from '../IconButtons/IconButtons';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {},
    hideOnMobile: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    playPauseButton: {
      margin: theme.spacing(0, 1),
    },
    audioControls: {
      display: 'flex',
    },
    volume: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(2),
      color: theme.palette.common.white,
      [theme.breakpoints.only('xs')]: {
        marginLeft: theme.spacing(0),
      },
    },
    volumeThumb: {
      display: 'none',
    },
    volumeTrackRail: { height: 8 },
    volumeRoot: {
      width: 55,
      height: 8,
      color: theme.palette.common.white,
      marginLeft: theme.spacing(0.25),
      [theme.breakpoints.only('xs')]: { display: 'none' },
    },
  })
);
export interface AudioPlayerControlsProps {}

export const AudioPlayerControls = ({}: AudioPlayerControlsProps) => {
  const classes = useStyles();
  const audio = useAudio();
  const [volume, setVolume] = useState<number>(100);

  const handleVolumeChange = (event: unknown, value: number | number[]) => {
    if (!audio.currentSound || Array.isArray(value)) {
      return;
    }
    setVolume(value);
    audio.currentSound.volume(value * 0.01);
  };

  const handleVolumeToggle = () => {
    handleVolumeChange({}, volume > 0 ? 0 : 100);
  };

  return audio.currentTrack ? (
    <div className={classes.container}>
      <div className={classes.audioControls}>
        <div className={classes.hideOnMobile}>
          <PreviousButton onClick={audio.onPrevious} large square />
        </div>
        <div className={classes.playPauseButton}>
          {audio.playing ? (
            <PauseButton onClick={audio.onPause} large />
          ) : (
            <PlayButton onClick={audio.onPlay} large />
          )}
        </div>
        <div className={classes.hideOnMobile}>
          <NextButton onClick={audio.onNext} large square />
        </div>
        <div className={classes.volume}>
          <IconButton onClick={handleVolumeToggle} color="inherit">
            {volume > 0 ? <VolumeUp /> : <VolumeMute />}
          </IconButton>
          <Slider
            classes={{
              thumb: classes.volumeThumb,
              rail: classes.volumeTrackRail,
              track: classes.volumeTrackRail,
              root: classes.volumeRoot,
            }}
            value={volume}
            defaultValue={volume}
            onChange={handleVolumeChange}
            min={0}
            max={100}
          />
        </div>
      </div>
    </div>
  ) : null;
};
