import { getSiteConfigs, updateSiteConfig } from '.';
import { useActionApiCall } from '../useActionApiCall';
import { useApiCall, UseApiOptions } from '../useApiCall';

export const useGetSiteConfigsApiCall = (options?: UseApiOptions) => {
  const result = useApiCall({
    apiCall: getSiteConfigs,
    variables: {},
    ...options,
  });
  return result;
};

export const useUpdateSiteConfigApiCall = (options?: UseApiOptions) => {
  const result = useActionApiCall({ apiCall: updateSiteConfig, ...options });
  return result;
};
