import { Coupon } from '../api/coupons';

export const applyCouponToAmount = (amount: number, coupon: Coupon): number => {
  if (coupon.amount_off) {
    const newAmount = amount - coupon.amount_off;
    return newAmount < 0 ? 0 : newAmount;
  } else if (coupon.percent_off) {
    const percent = (100 - coupon.percent_off) * 0.01;
    return amount * percent;
  }
  return amount;
};
