import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Button,
  TableRow,
  Typography,
  IconButton,
  Divider,
  Grid,
} from '@material-ui/core';

import { Delete } from '@material-ui/icons';
import { useDeleteUserApiCall } from '../../../../api/users/hooks';
import { GetUsersVariables } from '../../../../api/users';
import ConfirmationModal from './ConfirmationModal';
import EditUserModal from './EditUserModal';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      textAlign: 'left',
      padding: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
    mainContent: { background: theme.palette.background.default },
    button: {
      display: 'flex',
      margin: theme.spacing(1),
      marginLeft: theme.spacing(4),
    },
    table: {},
    userInfo: { fontWeight: 'bold' },
    firstCell: {
      align: 'left',
    },
    row: { padding: theme.spacing(1, 0, 1, 1) },
    deleteButton: { marginLeft: theme.spacing(5), padding: theme.spacing(1) },
  })
);

interface UserInfoDisplayProps {
  userInfo: GetUsersVariables;
  onDeleteUser: VoidFunction;
  onEditUser: VoidFunction;
}

export default function UserInfoDisplay({
  userInfo,
  onDeleteUser,
  onEditUser,
}: UserInfoDisplayProps) {
  const classes = useStyles();
  if (userInfo._id) {
    var userID: number = userInfo._id;
  }
  const deleteUser = useDeleteUserApiCall();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(
    false
  );
  const [editUserModalOpen, setEditUserModalOpen] = useState<boolean>(false);
  const handleDeleteUser = async () => {
    await deleteUser.runApiCall({ _id: userID });
    onDeleteUser();
  };
  const handleOpenConfirmationModal = () => {
    setConfirmationModalOpen(true);
  };
  const handleCloseConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };
  const handleOpenEditUserModal = () => {
    setEditUserModalOpen(true);
  };
  const handleCloseEditUserModal = () => {
    setEditUserModalOpen(false);
  };

  return (
    <>
      <TableRow>
        <Grid
          className={classes.row}
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={3}>
            <Typography align="left" variant="body2">
              {userInfo.name}
            </Typography>
            <Typography align="left" variant="body1">
              {'Joined: ' + userInfo.createdAt?.substr(0, 10)}
            </Typography>
            <Typography align="left" variant="body1">
              {userInfo.email}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography align="left" variant="body1">
              {userInfo.subscription_type != null
                ? 'Subscription: ' + userInfo.subscription_type
                : null}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenEditUserModal}
            >
              Edit
            </Button>
            <EditUserModal
              userName={userInfo.name}
              subscriptionType={userInfo.subscription_type}
              subscriptionStartDate={userInfo.subscription_start_date}
              subscriptionEndDate={userInfo.subscription_end_date}
              modalOpen={editUserModalOpen}
              onCancel={handleCloseEditUserModal}
              onEditUserInfo={onEditUser}
              userID={userInfo._id}
            />

            <IconButton
              className={classes.deleteButton}
              onClick={handleOpenConfirmationModal}
            >
              <Delete />
            </IconButton>
            <ConfirmationModal
              confirmButtonText="Delete User"
              modalOpen={confirmationModalOpen}
              onConfirm={handleDeleteUser}
              onCancel={handleCloseConfirmationModal}
            >
              Are you sure you want to delete this user?
            </ConfirmationModal>
          </Grid>
        </Grid>
      </TableRow>
      <Divider />
    </>
  );
}
