import { Button, makeStyles, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import { useState } from 'react';
import { CustomTheme } from '../../types';
import LoginForm from '../Auth/LoginForm/LoginForm';
import SignupForm from '../Auth/SignupForm/SignupForm';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {},
  })
);
export interface LoginSignupProps {
  onComplete: VoidFunction;
  showLoginFirst?: boolean;
}

export const LoginSignup = ({
  onComplete,
  showLoginFirst = false,
}: LoginSignupProps) => {
  const classes = useStyles();
  const [showLogin, setShowLogin] = useState<boolean>(showLoginFirst);

  return (
    <div>
      {showLogin ? (
        <div>
          <LoginForm onLogin={onComplete} />
          <Button onClick={() => setShowLogin(!showLogin)} variant="text">
            Need to create an account?
          </Button>
        </div>
      ) : (
        <div>
          <SignupForm onSignup={onComplete} />
          <Button onClick={() => setShowLogin(!showLogin)} variant="text">
            Already have an account?
          </Button>
        </div>
      )}
    </div>
  );
};
