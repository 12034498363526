import { useState, useEffect } from 'react';
import { useAudio } from '../providers/AudioProvider';

export const useAudioCurrentTime = () => {
  const audio = useAudio();
  const [currentTime, setCurrentTime] = useState<number>(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime((audio.currentSound?.seek() as number) || 0);
    }, 250);

    return () => {
      clearInterval(interval);
    };
  }, [audio.currentSound]);
  return currentTime;
};
