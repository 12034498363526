import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
} from '@material-ui/core';

import { Search } from '@material-ui/icons';
import {
  GetSearchUsersVariables,
  META_SUBSCRIPTION_TYPES,
} from '../../../../api/users';
import UserInfoDisplay from './UserInfoDisplay';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      textAlign: 'left',
      padding: theme.spacing(1),
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
    mainContent: { background: theme.palette.background.default },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(4),
    },
    table: {},
    tableHeader: { fontWeight: 'bold' },
    tableRow: { background: theme.palette.background.default },
    searchBar: {
      display: 'flex',
    },
    textFields: {
      marginTop: theme.spacing(0),
      width: '20vw',
    },
    labels: {
      marginLeft: theme.spacing(1),
    },
    subscriptionSelect: {
      width: '20vw',
      textAlign: 'left',
    },
  })
);
interface UsersSearchProps {
  onSearch: (searchVariables: GetSearchUsersVariables) => void;
  resetPageCount: (page: number) => void;
}

export default function UsersSearch({
  onSearch,
  resetPageCount,
}: UsersSearchProps) {
  const classes = useStyles();
  const [
    searchVariables,
    setSearchVariables,
  ] = useState<GetSearchUsersVariables>({
    name: '',
    email: '',
    subscription_type: '',
  });
  const handleSearchVariablesChange = (e: any) => {
    setSearchVariables({
      ...searchVariables,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnSearch = () => {
    onSearch(searchVariables);
    resetPageCount(0);
  };

  return (
    <>
      <Grid container direction="row" alignItems="center">
        <FormControl>
          <FormHelperText className={classes.labels}>Username</FormHelperText>
          <TextField
            name="name"
            defaultValue={searchVariables.name}
            className={classes.textFields}
            variant="outlined"
            placeholder="Search for username..."
            onChange={handleSearchVariablesChange}
          />
        </FormControl>
        <FormControl>
          <FormHelperText className={classes.labels}>Email</FormHelperText>
          <TextField
            name="email"
            defaultValue={searchVariables.email}
            className={classes.textFields}
            variant="outlined"
            placeholder="Search for email..."
            onChange={handleSearchVariablesChange}
          />
        </FormControl>
        <FormControl>
          <FormHelperText className={classes.labels}>
            Subscription Type
          </FormHelperText>
          <Select
            name="subscription_type"
            defaultValue={searchVariables.subscription_type}
            variant="outlined"
            className={classes.subscriptionSelect}
            onChange={handleSearchVariablesChange}
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {META_SUBSCRIPTION_TYPES.map((subscriptionType: string) => (
              <MenuItem value={subscriptionType}>{subscriptionType}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleOnSearch}
        >
          <Search />
        </Button>
      </Grid>
    </>
  );
}
