import { makeStyles, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import { CustomTheme } from '../../types';
import { MusicPackPage } from './MusicPackPage';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {},
    title: {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.only('xs')]: { fontSize: 24 },
    },
    image: {
      height: '100%',
      width: '100%',
      backgroundImage:
        'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://s3-us-west-2.amazonaws.com/pleasantpictures-images/keyboard.jpg)',
      minHeight: 300,
      backgroundSize: 'cover',
      display: 'flex',
      justifyContent: 'center',
      color: theme.palette.common.white,
      alignItems: 'center',
      backgroundPosition: 'center',
      '& > *:first-child': {
        maxWidth: 580,
        fontWeight: 400,
        [theme.breakpoints.only('xs')]: { fontSize: 24 },
      },
    },
  })
);
export interface BackgroundPackProps {}

export const BackgroundPack = ({}: BackgroundPackProps) => {
  const classes = useStyles();

  return (
    <MusicPackPage
      image={
        <div className={classes.image}>
          <Typography variant="h3" color="inherit">
            THE COMPLETE BACKGROUND MUSIC PACK
          </Typography>
        </div>
      }
      collectionName={'videographerCollectionID'}
    >
      <>
        <Typography variant="h3" className={classes.title}>
          The Right Music Makes a Huge Difference
        </Typography>
        As award winning composers, nothing bothers us more than watching a
        video with incredible visuals and then hearing cheesy or lazy music ruin
        the moment. Our mission is to never let this happen to those who view
        your work! That’s why we at Pleasant Pictures Music Club only stock our
        packs with high quality, original sounding tracks.
        <br /> The 30 tracks in The Complete Background Music Pack have been
        handpicked and chosen carefully to give you a diverse and powerful set
        of tracks to help you create confidently. Each track comes pre-edited in
        90/60/30/15/6 second lengths so you can drag and drop into your projects
        with less editing. Whether you are brand new or a seasoned veteran at
        videography, you will be adding a powerful arsenal of tracks to enhance
        your work!.
      </>
    </MusicPackPage>
  );
};
