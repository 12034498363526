import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import { CustomTheme } from '../../types';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {},
  })
);
export interface MusicSubscriptionLicenseProps {}

export const MusicSubscriptionLicense = ({}: MusicSubscriptionLicenseProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>
        <p>
          <b>
            Music Subscription License Agreement - Pleasant Pictures Music Club
          </b>
        </p>

        <p></p>

        <p>
          <b>
            <span>AGREEMENT</span>
          </b>
          <span>
            : This license agreement is made between You, the User or Licensee,{' '}
          </span>
          and the copyright owner Pleasant Pictures Library LLC, a Utah LLC
          doing business/DBA as the Pleasant Pictures Music Club (PPMC).
          <span>
            By clicking the box on the checkout screen, you state that you
            understand and accept these terms in this Agreement.
          </span>
        </p>

        <p>
          <b>LENGTH OF LICENSE.</b> You are granted a<b>one (1)</b>
          year license or subscription to the songs in the
          <span>
            Pleasant Pictures Music Club. During that year term, you are granted
            the rights to the song in all of the ways we describe here. After
            your subscription ends, the songs can remain part of any existing
            works or productions created within the subscription period as
            described in this Agreement. However, if you choose not to renew
            your subscription, you may not use any song for a new productions or
            work and must remove any PPMC songs from your files.
            <br />
            <br />
            <b>AVAILABILITY. </b>PPMC makes all possible efforts for the songs
            to be available at all times. However, sometimes mistakes happen. If
            we have any problems or issues with particular songs or services, we
            will communicate through notices on our website and/or the email
            account your provided on registration. PPMC may stop licensing
            certain songs at its sole discretion. In the unlikely event of a
            copyright infringement claim or dispute, PPMC may require user to
            stop using a song and remove it from its machines or files. In that
            highly unlikely event, PPMC shall provide you with reasonably
            comparable content free of charge, subject to the terms and
            conditions of this Agreement
          </span>
        </p>

        <p>
          <b>
            <span>LICENSING TERMS</span>
          </b>
          : Nothing in the Agreement shall give you any right, title, or
          interest to the songs, other than the right to use the Works in
          accordance with this agreement.
          <span> </span>PPMC grants these non-exclusive mechanical,
          synchronization, and performance rights to you during the
          one-year-term of licensing, including the right to:
        </p>

        <ol>
          <ol>
            <li>
              duplicate and release the song(s) as part of a video production in
              whatever format(s) necessary{' '}
            </li>
            <li>
              use the music as a soundtrack &quot;synced&quot; with visual
              images as part of a production; and{' '}
            </li>
            <li>
              right to use the music as part of the public viewing or broadcast
              of a production where the music is part of the production as
              defined by the previous two sub-points (a-b). You does not have
              the right to publicly perform WORKS on their own, separate and
              distinct from a production.
            </li>
          </ol>
        </ol>

        <p>
          <b>
            <span>RIGHTS NOT INCLUDED IN THIS AGREEMENT</span>
          </b>
          <span>.You, the Licensee are NOT allowed to :</span>
        </p>

        <ol>
          <ol>
            <li>
              <span></span>exercise any of the rights to the licensed songs
              after the expiration of the licensing term, except for songs that
              have become part of other works or productions during the
              licensing terms,
            </li>
            <li>
              claim authorship of the music represented under this agreement
              <span></span>or of any derivative musical work created from a
              song;
            </li>
            <li>
              <span></span>transfer, share or sub-lease this license agreement
              to any other party without written authorization
            </li>
            <li>retitle any of the songs</li>
            <li>
              copy or duplicate the works in any way except for use in your
              productions
            </li>
            <li>
              <a></a>
              <span></span>permit any other individual or third party the use of
              songs(s) by sharing, file-sharing, uploading or any other similar
              data-sharing process,
            </li>
            <li>
              <span></span>resell, trade, or exploit for profit the song(s)
              outright or as part of other music and/or audio-related
              collections, in part or in whole, to any other individual or party
              (although the music can be sold as part of a video production,
              which is a clear and distinct product from a song itself.) You
              cannot set up your own stock music library with files from PPMC.
            </li>
            <li>
              Make the recording available in any manner that would enable a
              third party to download the song as a separate file,
            </li>
            <li>
              Claim exclusive performance right or license. PPMC maintains the
              right to perform the song, or to transmit the work, to the public
              in any manner. You only have the public performance right for your
              productions that may contain songs from the PPMC Library.
            </li>
          </ol>
        </ol>

        <p>
          <b>
            <span>GOVERNING LAW:</span>
          </b>
          <span>
            {' '}
            The terms and provisions of the Agreement shall be interpreted in
            accordance with and governed by the laws of the State of Utah
            without regard to its conflict of law principles, and the state and
            federal courts in Utah shall have exclusive jurisdiction to resolve
            any disputes which may arise between the parties. All parties waive
            the right to trial by jury. In any legal action arising out of this
            agreement, the losing party shall pay the prevailing party's
            reasonable attorney's fees and costs.
          </span>
        </p>

        <p>
          <b>
            <span>DISPUTE RESOLUTION:</span>
          </b>
          <span>
            <span> </span>If there is any dispute between the parties arising
            under this Agreement that cannot be resolved informally then the
            parties respectively agree to submit the controversy to a single
            arbitrator of the American Arbitration Association under its
            then-applicable rules. The arbitration proceeding shall be held
            within Utah County. The parties agree that the award of the
            arbitrator will be final and binding on the parties and may be
            enforced or confirmed in any court of competent jurisdiction. The
            costs and expenses of the arbitration, including, without
            limitation, the arbitrator, attorneys' fees and costs, shall be
            apportioned between the parties by the arbitrator in his
            determination of the relative merits of each party's position.{' '}
          </span>
          <span></span>
        </p>

        <p>
          <b>
            <span>CREDITS</span>
          </b>
          <span>
            : You, the LICENSEE, are required to credit the song to Pleasant
            Pictures Music Club (in liner notes, rolling credits, verbal
            acknowledgment, etc.)
          </span>
        </p>

        <p>
          <b>FEES</b>: You, the LICENSEE agrees to pay the LICENSOR a licensing
          fee in fair consideration for the one-year subscription to the
          Library, beginning at the date of execution to this agreement. You,
          the LICENSEE will not owe any future additional royalties or fees to
          the LICENSOR for future use of the Work(s) within the terms of this
          AGREEMENT during this one-year licensing period.
          <span></span>
        </p>

        <p>
          <b>ADDITIONS</b>
          <span>
            : PPMC
            <span></span>intends to add additional works to the music library on
            an ongoing basis. Any additional songs will be subject to the same
            licensing terms as those set out in this Agreement.
          </span>
        </p>

        <p>
          <b>NO ASSIGNMENT:</b> This Agreement may not be assigned by the
          Licensee without the prior written approval of the management of
          Pleasant Pictures Library LLC.
          <span></span>
        </p>

        <p>
          <b>SEVERABILITY:</b>
          The parties intend as follows:
          <span></span>
        </p>

        <ol>
          <ol>
            <li>
              <span>
                That if any provision of this agreement is held to be
                unenforceable, then that provision will be modified to the
                minimum extent necessary to make it enforceable, unless that
                modification is not permitted by law, in which case that
                provision will be disregarded;{' '}
              </span>
            </li>
            <li>
              <span>
                That if an unenforceable provision is modified or disregarded in
                accordance with this section, the rest of the agreement will
                remain in effect as written;{' '}
              </span>
            </li>
            <li>
              <span>
                And that any enforceable provisions will remain as written in
                any circumstances other than those in which the provision is
                held to be unenforceable.
              </span>
            </li>
          </ol>
        </ol>

        <p></p>

        <p></p>
      </div>
    </div>
  );
};
