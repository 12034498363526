import {
  Button,
  CircularProgress,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useCheckoutTrackApiCall } from '../../../api/checkout/hooks';
import { Coupon } from '../../../api/coupons';
import { useGetTrackApiCall } from '../../../api/tracks/hooks';
import { AppRoutes } from '../../../constants/routes';
import { TRACK_PURCHASE_PRICE } from '../../../constants/values';
import { useCheckoutSignup } from '../../../hooks/useCheckoutSignup';
import ContentPageLayout from '../../../layouts/ContentPageLayout';
import { usePurchasedTracks } from '../../../providers/PurchasedTracksProvider';
import { useSession } from '../../../providers/SessionProvider';
import { CustomTheme } from '../../../types';
import { recordPurchase } from '../../../utils/affiliateProgram';
import { AnalyticsService } from '../../../utils/analytics';
import { applyCouponToAmount } from '../../../utils/applyCouponToAmount';
import CheckboxInput from '../../CheckboxInput/CheckboxInput';
import { CheckoutFields } from '../../CheckoutFields/CheckoutFields';
import { PageLoadingSpinner } from '../../PageLoadingSpinner/PageLoadingSpinner';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {
      '& > button': {
        marginTop: theme.spacing(3),
      },
    },
    form: {},
  })
);
export interface PurchaseTrackProps {}

export const PurchaseTrack = ({}: PurchaseTrackProps) => {
  const classes = useStyles();
  const match = useRouteMatch();
  const history = useHistory();
  const track = useGetTrackApiCall({ _id: (match.params as any).trackID });
  const purchaseTrack = useCheckoutTrackApiCall();
  const session = useSession();
  const [coupon, setCoupon] = useState<Coupon | null>(null);
  const [agreed, setAgreed] = useState<boolean>(false);
  const { refetch } = usePurchasedTracks();

  const handleCheckoutSuccess = () => {
    if (!session.user) {
      return;
    }
    recordPurchase({
      customer: { email: session.user.email },
      total: coupon
        ? applyCouponToAmount(TRACK_PURCHASE_PRICE, coupon)
        : TRACK_PURCHASE_PRICE,
      id: `TRACK:${track.data?._id}:USER:${session.user?._id}`,
    });
    refetch();
    history.push(
      AppRoutes.CHECKOUT_TRACK_SUCCESS +
        '/' +
        encodeURI(track?.data?.name || '')
    );
    if (track.data) {
      AnalyticsService().trackPurchased(track.data);
    }
  };

  const checkout = useCheckoutSignup({
    coupon,
    baseAmount: TRACK_PURCHASE_PRICE * 100,
    onSuccess: handleCheckoutSuccess,
    runApiCall: purchaseTrack.runApiCall,
  });

  useEffect(() => {
    if (!session.loading && !session.user) {
      history.push(AppRoutes.LOGIN);
    }
  }, [session]);

  return (
    <div className={classes.container}>
      <ContentPageLayout>
        <Typography color="error">{track.error}</Typography>
        {track.loading ? (
          <PageLoadingSpinner />
        ) : (
          <>
            <Typography variant="h6"> Purchase: {track.data?.name}</Typography>
            <div className={classes.form}>
              <CheckoutFields
                onCoupon={setCoupon}
                amountWithDiscount={checkout.amountWithDiscount}
              />
              <CheckboxInput
                checked={agreed}
                name={'agreed'}
                onChange={(e) => setAgreed(e.target.value)}
                label={
                  <>
                    I agree that by purchasing this track, I understand and will
                    comply with these{' '}
                    <Link
                      href={AppRoutes.PURCHASE_TERMS + '/' + track.data?.name}
                    >
                      licensing terms
                    </Link>{' '}
                    when using that music
                  </>
                }
              />
              <Button
                variant="contained"
                color="primary"
                disabled={checkout.loading || !agreed}
                onClick={() =>
                  checkout.completeCheckout({
                    trackID: track.data?._id as number,
                  })
                }
              >
                {checkout.loading ? <CircularProgress /> : 'Purchase'}
              </Button>
            </div>
          </>
        )}
      </ContentPageLayout>
    </div>
  );
};
