import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { AnalyticsService } from '../utils/analytics';

export const useAnalyticsPageTracking = () => {
  const history = useHistory();
  useEffect(() => {
    return history.listen(() => {
      AnalyticsService().pageView();
    });
  }, []);
};
