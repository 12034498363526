import React from 'react';
import Header from './components/Header/Header';
import { Route } from 'react-router-dom';
import AppContent from './components/AppContent';
import { useSession } from './providers/SessionProvider';
import LandingPage from './components/LandingPage/LandingPage';
import Footer from './components/Footer/Footer';
import LoginPage from './components/Auth/LoginPage/LoginPage';
import { AppRoutes } from './constants/routes';
import LogoutPage from './components/Auth/LogoutPage';
import SignupPage from './components/Auth/SignupPage/SignupPage';
import ForgotPasswordPage from './components/Auth/ForgotPasswordPage/ForgotPasswordPage';
import ResetPasswordPage from './components/Auth/ResetPasswordPage.tsx/ResetPasswordPage';
import Admin from './components/Admin/Admin';
import { AudioPlayer } from './components/AudioPlayer/AudioPlayer';
import { BuyNowPage } from './components/BuyNowPage/BuyNowPage';
import { CheckoutSuccess } from './components/CheckoutSuccess/CheckoutSuccess';
import { PurchaseTrack } from './components/Admin/PurchaseTrack/PurchaseTrack';
import { CheckoutTrackSuccess } from './components/CheckoutSuccess/CheckoutTrackSuccess';
import { StockMusicPack } from './components/MusicPackPages/StockMusicPack';
import { HighEndMusicPack } from './components/MusicPackPages/HighEndMusicPack';
import { CheckoutTrackPackSuccess } from './components/CheckoutSuccess/CheckoutTrackPackSuccess';
import { BackgroundPack } from './components/MusicPackPages/BackgroundPack';
import classes from '*.module.css';
import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import { CustomTheme } from './types';
import { SettingsPage } from './components/SettingsPage/SettingsPage';
import { MyLibrary } from './components/MyLibrary/MyLibrary';
import { FavoriteTracksPage } from './components/FavoriteTracksPage/FavoriteTracksPage';
import { AboutUsPage } from './components/AboutUsPage/AboutUsPage';
import { CustomMusicPage } from './components/CustomMusicPage/CustomMusicPage';
import { FAQPage } from './components/FAQPage/FAQPage';
import { Terms } from './components/TermsAndPolicy/Terms';
import { PrivacyPolicy } from './components/TermsAndPolicy/PrivacyPolicy';
import { PurchaseTerms } from './components/TermsAndPolicy/PurchaseTerms';
import { MusicSubscriptionLicense } from './components/TermsAndPolicy/MusicSubscriptionLicense';
import { useAnalyticsPageTracking } from './hooks/useAnalyticsPageTracking';
const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    appContainer: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
  })
);

const App = React.memo(() => {
  const classes = useStyles();
  const session = useSession();
  useAnalyticsPageTracking();

  // Doing this hard coded as false to see how it reacts when dynamically adding a session without refreshing the entire app
  return session.loading && false ? (
    <></>
  ) : (
    <div className={classes.appContainer}>
      <Header />
      <AppContent>
        <Route exact path={AppRoutes.LANDING} component={LandingPage} />
        <Route exact path={AppRoutes.LOGIN} component={LoginPage} />
        <Route exact path={AppRoutes.SIGNUP} component={SignupPage} />
        <Route exact path={AppRoutes.LOGOUT} component={LogoutPage} />
        <Route exact path={AppRoutes.ADMIN} component={Admin} />
        <Route exact path={`${AppRoutes.ADMIN}/*`} component={Admin} />
        <Route exact path={AppRoutes.PLANS} component={BuyNowPage} />
        <Route
          exact
          path={AppRoutes.PURCHASE_TRACK + '/:trackID'}
          component={PurchaseTrack}
        />
        <Route
          exact
          path={AppRoutes.CHECKOUT_SUCCESS}
          component={CheckoutSuccess}
        />
        <Route
          exact
          path={AppRoutes.CHECKOUT_TRACK_SUCCESS + '/:name'}
          component={CheckoutTrackSuccess}
        />
        <Route
          exact
          path={AppRoutes.CHECKOUT_TRACK_PACK_SUCCESS}
          component={CheckoutTrackPackSuccess}
        />
        <Route
          exact
          path={AppRoutes.FORGOT_PASSWORD}
          component={ForgotPasswordPage}
        />
        <Route
          exact
          path={AppRoutes.RESET_PASSWORD}
          component={ResetPasswordPage}
        />
        <Route
          exact
          path={AppRoutes.STOCK_MUSIC_PACK}
          component={StockMusicPack}
        />
        <Route
          exact
          path={AppRoutes.BACKGROUND_PACK}
          component={BackgroundPack}
        />
        <Route
          exact
          path={AppRoutes.HIGH_END_PACK}
          component={HighEndMusicPack}
        />
        <Route exact path={AppRoutes.MY_LIBRARY} component={MyLibrary} />
        <Route
          exact
          path={`${AppRoutes.FAVORITES}/:id`}
          component={FavoriteTracksPage}
        />
        <Route exact path={AppRoutes.SETTINGS} component={SettingsPage} />
        <Route exact path={AppRoutes.ABOUT_US} component={AboutUsPage} />
        <Route exact path={AppRoutes.CUSTOM} component={CustomMusicPage} />
        <Route exact path={AppRoutes.FAQ} component={FAQPage} />
        <Route exact path={AppRoutes.TERMS} component={Terms} />
        <Route exact path={AppRoutes.POLICY} component={PrivacyPolicy} />
        <Route
          exact
          path={`${AppRoutes.PURCHASE_TERMS}/:name`}
          component={PurchaseTerms}
        />
        <Route
          exact
          path={AppRoutes.SUBSCRIPTION_TERMS}
          component={MusicSubscriptionLicense}
        />
      </AppContent>
      <AudioPlayer />
      <Footer />
    </div>
  );
});

export default App;
