import React, { ChangeEvent } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  TableRow,
  TableCell,
  IconButton,
  MenuItem,
  TextField,
} from '@material-ui/core';

import { Delete } from '@material-ui/icons';
import {
  useDeleteTagApiCall,
  useUpdateTagApiCall,
} from '../../../../api/tags/hooks';
import { Tag, DeleteTagBody, TAG_CATEGORIES } from '../../../../api/tags/';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      textAlign: 'left',
      padding: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
    mainContent: { background: '#D8D8D8' },
    button: {
      display: 'flex',
      margin: theme.spacing(1),
      marginLeft: theme.spacing(4),
    },
    deleteButton: {
      display: 'flex',
      marginLeft: theme.spacing(2.5),
    },
    table: {},
    tableHeader: { fontWeight: 'bold' },
    tableRow: { background: theme.palette.background.default, width: '100%' },
  })
);

interface TagListItemProps {
  tag: Tag;
  onUpdateTag: VoidFunction;
  onDeleteTag: VoidFunction;
}

export default function TagListItem({
  tag,
  onUpdateTag,
  onDeleteTag,
}: TagListItemProps) {
  const classes = useStyles();
  const result = useUpdateTagApiCall();
  const deleteResult = useDeleteTagApiCall();
  const handleDeleteTag = async (deleteTagBody: DeleteTagBody) => {
    await deleteResult.runApiCall(deleteTagBody);
    onDeleteTag();
  };
  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    await result.runApiCall({ category: e.target.value, _id: tag._id });
    onUpdateTag();
  };
  return (
    <>
      <TableRow>
        <TableCell>{tag.name}</TableCell>
        <TableCell align="left">
          <TextField select defaultValue={tag.category} onChange={handleChange}>
            {TAG_CATEGORIES.map((category) => (
              <MenuItem value={category}>{category}</MenuItem>
            ))}
          </TextField>
        </TableCell>
        <TableCell align="center">
          <IconButton onClick={() => handleDeleteTag({ _id: tag._id })}>
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}
