import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Typography, Button, Dialog } from '@material-ui/core';
import TagsList from './TagsList';
import NewTagForm from './NewTagForm';
import { useGetTagsApiCall } from '../../../../api/tags/hooks';
import { META_TAG_CATEGORIES, Tag } from '../../../../api/tags';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '74vw',
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    textAlign: 'left',
    padding: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  button: {
    display: 'flex',
    margin: theme.spacing(1),
    marginLeft: theme.spacing(4),
  },
  table: {},
  tableHeader: { fontWeight: 'bold' },
  tableRow: { background: theme.palette.background.default },
}));

export default function Tags() {
  const classes = useStyles();
  const result = useGetTagsApiCall();
  const [selectedTab, setSelectedTab] = useState<string>('All');
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [filteredSortedTags, setFilteredSortedTags] = useState<Tag[]>([]);

  useEffect(() => {
    if (result.data) {
      const newFilteredTags = result.data
        .filter((tag) => {
          if (selectedTab === 'All') {
            return true;
          }
          if (selectedTab === tag.category) {
            return true;
          }
          if (selectedTab === 'No Category' && !tag.category) {
            return true;
          }
          return false;
        })
        .sort((tag1, tag2) =>
          tag1.name.toLowerCase() > tag2.name.toLowerCase() ? 1 : -1
        );
      setFilteredSortedTags(newFilteredTags);
    }
  }, [selectedTab, result.data]);

  const handleSelectedTabChange = (event: any, newValue: string) => {
    setSelectedTab(newValue);
  };

  const handleModalOpen = () => {
    setCreateModalOpen(true);
  };

  const handleModalClose = () => {
    setCreateModalOpen(false);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        Tags
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        disabled={result.loading}
        onClick={handleModalOpen}
      >
        Add New
      </Button>
      <Dialog
        open={createModalOpen}
        onClose={handleModalClose}
        maxWidth={'sm'}
        fullWidth={true}
      >
        <NewTagForm
          handleModalClose={handleModalClose}
          onCreateTag={result.refetch}
        />
      </Dialog>
      <div>
        <Tabs
          value={selectedTab}
          onChange={handleSelectedTabChange}
          variant="scrollable"
          scrollButtons="on"
        >
          {META_TAG_CATEGORIES.map((tab) => (
            <Tab value={tab} label={tab} />
          ))}
        </Tabs>

        <TagsList
          tags={filteredSortedTags}
          onUpdateTag={result.refetch}
          onDeleteTag={result.refetch}
          dataLoaded={result.loading}
        />
      </div>
    </div>
  );
}
