import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { CreditCard, Lock, MusicNote } from '@material-ui/icons';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import WithEdgePaddingLayout from '../../layouts/WithEdgePaddingLayout';
import { CustomTheme } from '../../types';
import { AccountSettings } from './AccountSettings';
import { PaymentSettings } from './PaymentSettings';
import { SubscriptionSettings } from './SubsriptionSettings';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: { marginTop: theme.spacing(3) },
  })
);
export interface SettingsPageProps {}

const accordions = [
  { Icon: MusicNote, title: 'Susbcription', Content: SubscriptionSettings },
  { Icon: CreditCard, title: 'Payment Info', Content: PaymentSettings },
  { Icon: Lock, title: 'Account Settings', Content: AccountSettings },
];

export const SettingsPage = ({}: SettingsPageProps) => {
  const classes = useStyles();

  return (
    <WithEdgePaddingLayout>
      <div className={classes.container}>
        <Typography variant="h3">Settings</Typography>
        {accordions.map(({ Icon, title, Content }) => (
          <Accordion key={title}>
            <AccordionSummary>
              <Icon />
              <Typography>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Content />
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </WithEdgePaddingLayout>
  );
};
