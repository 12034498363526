import {
  Button,
  Checkbox,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React, {
  ChangeEvent,
  ChangeEventHandler,
  FormEvent,
  useEffect,
  useState,
} from 'react';
import {
  useUpdateUserApiCall,
  useUpdateUserPasswordApiCall,
} from '../../api/users/hooks';
import { useSession } from '../../providers/SessionProvider';
import { CustomTheme } from '../../types';
import CheckboxInput from '../CheckboxInput/CheckboxInput';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {},
    passwordReset: {
      display: 'flex',
      flexDirection: 'column',
    },
  })
);
export interface AccountSettingsProps {}

export const AccountSettings = ({}: AccountSettingsProps) => {
  const classes = useStyles();
  const session = useSession();
  const updateUser = useUpdateUserApiCall();
  const [passwordData, setPasswordData] = useState<{
    password: string;
    currentPassword: string;
    passwordConfirm: string;
    [key: string]: string;
  }>({ password: '', currentPassword: '', passwordConfirm: '' });
  const [localChecked, setLocalChecked] = useState<boolean>(false);
  const updatePasswordResult = useUpdateUserPasswordApiCall();
  useEffect(() => {
    if (session.user) {
      setLocalChecked(session.user.notifications);
    }
  }, [session?.user?.notifications]);
  const handleChange = async () => {
    setLocalChecked(!localChecked);
    updateUser.runApiCall({
      ...session.user,
      _id: 'me' as any,
      notifications: !localChecked,
    });
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPasswordData({
      ...passwordData,
      [event.target.name]: event.target.value,
    });
  };

  const passwordDataIsValid = Boolean(
    passwordData.password &&
      passwordData.currentPassword &&
      passwordData.password === passwordData.passwordConfirm
  );

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (!passwordDataIsValid || !session.user) {
      return;
    }
    try {
      await updatePasswordResult.runApiCall({
        _id: session.user._id,
        oldPassword: passwordData.currentPassword,
        newPassword: passwordData.password,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className={classes.container}>
      {session.loading ? (
        'Loading...'
      ) : (
        <>
          <CheckboxInput
            name="notifications"
            label="I would like to receive newsletters containing useful information about Pleasant Pictures Music Club."
            checked={localChecked}
            onChange={handleChange}
          />
          <form className={classes.passwordReset} onSubmit={handleSubmit}>
            <TextField
              type={'password'}
              name="currentPassword"
              label="Current Password"
              onChange={handlePasswordChange}
            />
            <TextField
              type={'password'}
              name="password"
              label="Password"
              onChange={handlePasswordChange}
            />
            <TextField
              type={'password'}
              name="passwordConfirm"
              label="Confirm Password"
              onChange={handlePasswordChange}
            />
            <Button
              type="submit"
              disabled={!passwordDataIsValid || updatePasswordResult.loading}
            >
              Submit
            </Button>
            <Typography variant="caption" color="error">
              {updatePasswordResult.error && 'Invalid password'}
            </Typography>
            <Typography variant="caption">
              {updatePasswordResult.data && 'Your password has been updated'}
            </Typography>
          </form>
        </>
      )}
    </div>
  );
};
