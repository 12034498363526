import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => createStyles({}));

interface ConfirmationModalProps {
  children: string;
  confirmButtonText: string;
  modalOpen: boolean;
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
}

export default function ConfirmationModal({
  children,
  confirmButtonText,
  modalOpen,
  onConfirm,
  onCancel,
}: ConfirmationModalProps) {
  const classes = useStyles();

  const handleClickConfirm = () => {
    onConfirm();
  };

  const handleClickCancel = () => {
    onCancel();
  };

  return (
    <>
      <Dialog open={modalOpen} onClose={handleClickCancel}>
        <DialogContent>
          <DialogContentText>{children}</DialogContentText>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickConfirm}
            >
              {confirmButtonText}
            </Button>
            <Button onClick={handleClickCancel} autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
