import { makeStyles, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import { useRouteMatch } from 'react-router';
import { useGetFavoriteTracksApiCall } from '../../api/favoriteTracks/hooks';
import WithEdgePaddingLayout from '../../layouts/WithEdgePaddingLayout';
import { CustomTheme } from '../../types';
import { TrackList } from '../TrackList/TrackList';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: { textAlign: 'center' },
    tracks: {},
  })
);
export interface FavoriteTracksPageProps {}

export const FavoriteTracksPage = ({}: FavoriteTracksPageProps) => {
  const classes = useStyles();
  const routeParams = useRouteMatch<{ id?: string }>();
  const favoriteTracks = useGetFavoriteTracksApiCall({
    userID: (routeParams.params.id as unknown) as number,
  });

  return (
    <WithEdgePaddingLayout>
      <div className={classes.container}>
        <Typography variant="h3">Favorite Tracks</Typography>
        <div className={classes.tracks}>
          <TrackList tracks={favoriteTracks.data?.map((t) => t.Track) || []} />
        </div>
      </div>
    </WithEdgePaddingLayout>
  );
};
